import React, { useState, useEffect, useCallback } from 'react'

import { gql, useQuery, useMutation } from '@apollo/client'

import { makeStyles } from '@material-ui/core/styles'

import { CreateInventory } from './CreateInventory'
import SelectMachine from '../components/selectMachine'

import Stocks from './Stocks'

import Button from '@material-ui/core/Button'

import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import Grid from '@material-ui/core/Grid'
import Title from '../components/Title'

import Typography from '@material-ui/core/Typography'

import { useStatefulSearchParam } from '../hooks'
import { getTextForLocale } from '../helpers'
import Spinner from '../components/spinner'

import {
  Category,
  HasMachineHadRefillTodayDocument,
  HasMachineHadRefillTodayQuery,
  MachineByIdDocument,
  MachineByIdQuery,
  Product,
  MenuItem,
  UpdateStockInput,
  SetManyChannelStockDocument,
  SetManyChannelStockMutation,
  StockTransactionV2Input,
  TransactionType,
  StockTransactionV2InventoryInput
} from '../generated'

const INVENTORY_STOCK_TRANSACTION_V2 = gql`
  mutation InventoryStockTransactionV2($input: StockTransactionV2InventoryInput!) {
    inventoryStockTransactionV2(input: $input) {
      balance
    }
  }
`;

const CREATE_STOCK_TRANSACTION_MUTATION_V2 = gql`
  mutation CreateStockTransactionV2($input: StockTransactionV2Input!) {
    createStockTransactionV2(input: $input) {
      id
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  toRight: { padding: theme.spacing(2), paddingLeft: '0' },
  toLeft: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingRight: '0',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

const TotalRow = ({ title, value }: { title: string; value: number }) => (
  <div style={{ display: 'flex' }}>
    <div style={{ width: '60px', marginRight: '5px' }}>
      <Typography component="p" color="textSecondary" variant="subtitle2">
        {title}
      </Typography>
    </div>
    <Typography component="p" color="textSecondary" variant="subtitle2">
      {value}
    </Typography>
  </div>
)

type WorkingStock = {
  id: string
  quantity: number
  channel: number
  menuItem: MenuItem
}

const InventoryScreen = () => {
  const classes = useStyles()
  const [setManyChannelStock] = useMutation<SetManyChannelStockMutation>(
    SetManyChannelStockDocument
  )
  const [open, setOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [workingStocks, setWorkingStocks] = useState<{
    [key: string]: Omit<WorkingStock, 'id'>
  }>({})
  const [machineId, setMachineId] = useStatefulSearchParam({
    key: 'machineId',
    defaultValue: '96bea44e-7c42-45d9-897d-118fcb8274b7',
  })
  const [isRefill, setIsRefill] = useState<boolean | null>(null)

  const { loading, error, data } = useQuery<MachineByIdQuery>(
    MachineByIdDocument,
    { variables: { id: machineId } }
  )
  const [stockTransactionV2Inventory] = useMutation(INVENTORY_STOCK_TRANSACTION_V2)
  const [createStockTransactionV2] = useMutation(CREATE_STOCK_TRANSACTION_MUTATION_V2)
  const { loading: refillLoading, data: refillData } =
    useQuery<HasMachineHadRefillTodayQuery>(HasMachineHadRefillTodayDocument, {
      variables: { machineId },
    })

  
    const resetWorkingStocks = useCallback(() => {
      setWorkingStocks(
        data?.machineById.menuItems?.reduce(
          (acc, { stocks }) => ({
            ...acc,
            ...stocks?.reduce(
              (acc, { id, quantity, channel, menuItem }) => ({
                ...acc,
                [id]: { quantity, channel, menuItem },
              }),
              {}
            ),
          }),
          {}
        ) ?? {}
      )
    }, [data])

  useEffect(() => {
    setEditMode(false)
  }, [machineId])

  useEffect(() => {
    if (data) {
      resetWorkingStocks()
    }
  }, [data, resetWorkingStocks])

  useEffect(() => {
    if (refillLoading) {
      setIsRefill(null)
    }
  }, [refillLoading])

  useEffect(() => {
    if (refillData?.hasMachineHadRefillToday !== undefined)
      setIsRefill(!refillData.hasMachineHadRefillToday)
  }, [refillData])

  if (loading || !data) return <Spinner />
  if (error) return <p>Error :( {JSON.stringify(error, null, 2)}</p>


  const handleEditModeClicked = async () => {
    let todoResupply:StockTransactionV2Input[] = [];
    let todoInventory:StockTransactionV2InventoryInput[] = [];
    if (editMode) {
      const stocksToUpdate = data.machineById.menuItems
        ?.flatMap(
          (m) =>
            m.stocks?.map((s) => ({
              id: s.id,
              channel: s.channel,
              quantity: s.quantity,
              menuItem: s.menuItem,
            })) as WorkingStock[]
        )
        .filter(
          (s) =>
            s.channel !== +workingStocks[s.id].channel ||
            s.quantity !== +workingStocks[s.id].quantity ||
            s.menuItem.id !== workingStocks[s.id].menuItem.id
        )
        .map((s) => ({
          id: s.id,
          quantity: +workingStocks[s.id].quantity,
          channel: +workingStocks[s.id].channel,
          menuItemId: workingStocks[s.id].menuItem.id,
          productId: workingStocks[s.id].menuItem.product.id
        })) as UpdateStockInput[]
      // Here have we stocks to update
      if (
        stocksToUpdate.some(
          (s) =>
            (s?.quantity && isNaN(s.quantity)) ||
            (s?.channel && isNaN(s.channel))
        )
      ) {
        resetWorkingStocks()
      } else {
        if (isRefill) {
          await Promise.all([
            stocksToUpdate.forEach((s) => {
              const oldrecs = data.machineById.menuItems
              ?.flatMap(
                (m) =>
                  m.stocks?.map((s) => ({
                    id: s.id,
                    channel: s.channel,
                    quantity: s.quantity,
                    menuItem: s.menuItem,
                  }))
              )
              const oldrec = oldrecs.filter(o => 
                o.channel === s.channel
              )[0];
              let oldBalance = oldrec ? oldrec.quantity : 0;
              // If we have changed channel or product(menuItem),
              // we "delete" (by setting inventory to 0) the old
              // record and queue up the new record in todo array
              if (
                s.productId !== oldrec.menuItem.product.id ||
                s.channel !== oldrec.channel) {
                  stockTransactionV2Inventory({
                    variables: {
                      input: {
                        machineId,
                        productId: oldrec.menuItem.product.id,
                        channel: oldrec.channel,
                        balance: 0,
                      }
                    }
                  })
                  oldBalance = 0;
                  const todoInput:StockTransactionV2Input = {
                    machineId,
                    productId: s.productId || '',
                    channel: s.channel || 0,
                    quantity: (s.quantity ? s.quantity : 0) - oldBalance,
                    transactionType: TransactionType.Resupply,
                  }
                  todoResupply.push(todoInput)
              } else {
                createStockTransactionV2({
                  variables: {
                    input: {
                      machineId,
                      productId: s.productId,
                      channel: s.channel,
                      quantity: (s.quantity ? s.quantity : 0) - oldBalance,
                      transactionType: 'RESUPPLY',
                    }
                  }
                })
              }
            }) 
          ]);          
        } else {
          await Promise.all([
            stocksToUpdate.forEach((s) => {
              const oldrecs = data.machineById.menuItems
              ?.flatMap(
                (m) =>
                  m.stocks?.map((s) => ({
                    id: s.id,
                    channel: s.channel,
                    quantity: s.quantity,
                    menuItem: s.menuItem,
                  }))
              )
              const oldrec = oldrecs.filter(o => 
                o.channel === s.channel
              )[0];
              let oldBalance = oldrec ? oldrec.quantity : 0;
              // If we have changed channel or product(menuItem),
              // we "delete" (by setting inventory to 0) the old
              // record and queue up the new record in todo array
              if (
                s.productId !== oldrec.menuItem.product.id ||
                s.channel !== oldrec.channel) {
                stockTransactionV2Inventory({
                  variables: {
                    input: {
                      machineId,
                      productId: oldrec.menuItem.product.id,
                      channel: oldrec.channel,
                      balance: 0,
                    }
                  }
                })
                oldBalance = 0;
                const todoInput:StockTransactionV2InventoryInput = {
                  machineId,
                  productId: s.productId || '',
                  channel: s.channel || 0,
                  balance: (s.quantity ? s.quantity : 0) - oldBalance,
                }
                todoInventory.push(todoInput)
              }
                                           
              stockTransactionV2Inventory({
                variables: {
                  input: {
                    machineId,
                    productId: s.productId,
                    channel: s.channel,
                    balance: s.quantity
                  }
                }
              })
            }) 
          ]);
        } 
        await setManyChannelStock({
          variables: {
            input: { stocks: stocksToUpdate, isRefill, machineId },
          },
          refetchQueries: [
            { query: MachineByIdDocument, variables: { id: machineId } },
            {
              query: HasMachineHadRefillTodayDocument,
              variables: { machineId },
            },
          ],
        })
        // If any changed product id or channel, we have them in todo array 
        // and process them here
        if (todoResupply.length) {
          for (let i=0;i<todoResupply.length;i++) {
            await createStockTransactionV2({
              variables: {
                  input: todoResupply[i],
              }
            })
          }
        }
        todoResupply = [];
        if (todoInventory.length) {
          for (let i=0;i<todoInventory.length;i++) {
            await stockTransactionV2Inventory({
              variables: {
                input: todoInventory[i],
              }
            })
          }
        }
        todoInventory = [];
      }
      setEditMode(false)
    } else {
      setEditMode(true)
    }
  }

  const handleCancelClick = () => {
    resetWorkingStocks()
    setEditMode(false)
  }

  const total = data.machineById.menuItems?.reduce(
    (acc, curr) => {
      const menuItemQuantity =
        curr.stocks?.reduce((total, { quantity }) => total + quantity, 0) ?? 0
      const categoryKey =
        curr.product?.category === Category.Drink ? 'drinks' : 'food'
      return { ...acc, [categoryKey]: acc[categoryKey] + menuItemQuantity }
    },
    { food: 0, drinks: 0 }
  ) ?? { food: 0, drinks: 0 }

  const sortedMenuItems = data.machineById.menuItems
    ?.slice()
    .filter((m) => m.product && m.product.texts)
    .sort((a, b) =>
      (getTextForLocale(a.product as Product).name ?? '') >
      (getTextForLocale(b.product as Product).name ?? '')
        ? 1
        : -1
    )

  const sortedStocks = data.machineById.menuItems
    ?.flatMap((m) => m.stocks)
    .slice()
    .sort((a, b) => ((a?.channel ?? 0) < (b?.channel ?? 0) ? 1 : -1))

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Title>Inventory</Title>
          <TotalRow value={total.food} title={'Food total'}></TotalRow>
          <TotalRow value={total.drinks} title={'Drink total'}></TotalRow>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.toLeft}>
            <SelectMachine
              machineId={machineId}
              setMachineId={setMachineId}
              allowAll={false}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Channel</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell style={{ minWidth: 150 }}>Quantity</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Stocks
                    machineId={machineId}
                    loading={loading}
                    error={error}
                    stocks={sortedStocks}
                    menuItems={sortedMenuItems}
                    editMode={editMode}
                    workingStocks={workingStocks}
                    setWorkingStocks={setWorkingStocks}
                    channels={data.machineById.channels}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <CreateInventory
          open={open}
          setOpen={setOpen}
          machineId={machineId}
          menuItems={sortedMenuItems}
          allChannels={data.machineById.channels}
          availableChannels={data.machineById.channels.filter(
            (c) =>
              !data.machineById.menuItems
                ?.flatMap((m) => m.stocks)
                .some((m) => m?.channel === c)
          )}
        />

        <Grid item xs={12}>
          <div className={classes.toLeft}>
            {editMode ? (
              <>
                <FormControlLabel
                  label="Is this a refill?"
                  color="primary"
                  control={
                    <Switch
                      color="primary"
                      disabled={isRefill === null}
                      checked={isRefill || isRefill === null}
                      onChange={(e) => setIsRefill(e.target.checked)}
                    />
                  }
                />
                <Button
                  color={'secondary'}
                  variant="contained"
                  onClick={handleEditModeClicked}
                  style={{ marginRight: '6px' }}
                  disabled={!data}
                >
                  SAVE INVENTORY
                </Button>
                <Button
                  variant="contained"
                  onClick={handleCancelClick}
                  style={{ marginRight: '6px' }}
                >
                  CANCEL
                </Button>
              </>
            ) : (
              <>
                <Button
                  color={'primary'}
                  variant="contained"
                  onClick={handleEditModeClicked}
                  style={{ marginRight: '6px' }}
                  disabled={!data}
                >
                  EDIT INVENTORY
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpen(true)}
                >
                  + ADD INVENTORY
                </Button>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default InventoryScreen
