import React, { useEffect } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import { validateDates } from '../helpers'
import enLocale from 'date-fns/locale/en-GB'
import FormLabel from '@material-ui/core/FormLabel'

const DateIntervalPicker = ({
  start,
  onStartChange,
  end,
  onEndChange,
  setValidity,
  label,
  includeTime,
}: {
  start: Date | null
  onStartChange: (newDate: Date | null) => void
  end: Date | null
  onEndChange: (newDate: Date | null) => void
  setValidity?: (validity: {
    start: { isValid: boolean; message: string }
    end: { isValid: boolean; message: string }
  }) => void
  label?: string
  includeTime?: boolean
}) => {
  useEffect(() => {
    if (setValidity) {
      setValidity(validateDates(start, end))
    }
  }, [start, end, setValidity])

  const validity = validateDates(start, end)

  const PickerComponent = includeTime
    ? KeyboardDateTimePicker
    : KeyboardDatePicker

  return (
    <div>
      {label && <FormLabel>{label}</FormLabel>}

      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <PickerComponent
            disableToolbar
            variant="inline"
            format={`dd/MM/yyyy${includeTime ? ' - HH:mm' : ''}`}
            margin="normal"
            label="Start"
            error={!validity.start.isValid}
            helperText={validity.start.message}
            value={start}
            onChange={onStartChange}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
            style={{ marginRight: '6px' }}
            autoOk={true}
          />
          <PickerComponent
            disableToolbar
            variant="inline"
            format={`dd/MM/yyyy${includeTime ? ' - HH:mm' : ''}`}
            margin="normal"
            label="End"
            error={!validity.end.isValid}
            helperText={validity.end.message}
            value={end}
            onChange={onEndChange}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
            autoOk={true}
          />
        </div>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default DateIntervalPicker
