import { Locale, MenuItem, Product, ProductText } from './generated'

const thousandSeparators = (x: number | string) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const toSekString = (amount: number) => {
  if (amount === 0 || amount) {
    if (amount % 1 === 0) {
      return thousandSeparators(amount) + ' SEK'
    } else {
      const rounded = Math.round(amount * 100) / 100
      return thousandSeparators(rounded.toFixed(2)) + ' SEK'
    }
  } else return '0 SEK'
}

const TAX_RATIO = 1.12

export const afterTax = (amount: number) => amount / TAX_RATIO

export const validateDates = (start: any, end: any) => {
  const validity = {
    start: { isValid: true, message: '' },
    end: { isValid: true, message: '' },
  }
  if (start && (isNaN(start) || !(start instanceof Date))) {
    validity.start = { isValid: false, message: 'Invalid Date' }
  } else if (start?.getTime() > end?.getTime()) {
    validity.start = {
      isValid: false,
      message: 'Start must be earlier than end',
    }
  }

  if (end && (isNaN(end) || !(end instanceof Date))) {
    validity.end = { isValid: false, message: 'Invalid Date' }
  }

  return validity
}

export const getTextForLocale = (
  product?: (DeepPartial<Product> & { texts: Partial<ProductText>[] }) | null,
  locale = Locale.Se
) =>
  product?.texts?.find((t) => t?.locale === locale) ||
  product?.texts?.[0] || {
    name: 'Name missing',
    description: 'Description missing',
    allergens: 'Allergens missing',
  }

export const findProductById = <
  PartialText extends { id: ProductText['id'] },
  PartialProduct extends { texts: PartialText[] },
  PartialMenuItem extends { product: PartialProduct; id: MenuItem['id'] }
>(
  menuItems: PartialMenuItem[],
  id: string
) => {
  const result = menuItems.find((item) => item.id === id)

  return result && { id: result.id, product: result.product }
}

export const fieldMask = <T, K extends keyof T>(obj: T, keys: K[]) =>
  keys.reduce((acc, curr) => ({ ...acc, [curr]: obj[curr] }), {}) as Pick<T, K>

export const callIfValueIsNumericOrEmpty = (
  value: string,
  cb: (value: number | null) => void
) => {
  if (value === '') {
    cb(null)
  } else if (!isNaN(+value)) {
    cb(+value)
  }
}
