import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'

import Title from '../components/Title'
import Spinner from '../components/spinner'
import { LocationTypesDocument } from '../generated'

const useStyles = makeStyles((theme) => ({
  tableHead: { fontWeight: 'bold' },
  link: { '&:hover': { cursor: 'pointer', color: theme.palette.primary.main } },
}))

const LocationTypesScreen = () => {
  const classes = useStyles()
  const { loading, error, data, refetch } = useQuery(LocationTypesDocument)

  useEffect(() => {
    console.log({data})
    if (data) {
      refetch()
    }
  }, [data, refetch])

  if (loading) return <Spinner />
  if (error) return <p>Error {JSON.stringify(error, null, 2)}</p>

  const tableHeaders = ['ID', 'Type Name'];

  // const sortMachines = (a, b) => {
  //   const activeDiff = (b.active ? 1 : 0) - (a.active ? 1 : 0)
  //   if (activeDiff !== 0) return activeDiff
  //   return a.location.localeCompare(b.location)
  // }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Title>Location Types</Title>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button
              to={`/settings/locationtypes/create`}
              variant="contained"
              color="primary"
              component={Link}
          >
            + Add new
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((h) => (
                <TableCell className={classes.tableHead}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.locationTypes
              .slice()
              .map((locationType) => (
                <TableRow key={locationType.id}>
                  <TableCell style={{ width: '330px' }}>
                    <Link
                      to={`/settings/locationtypes/${locationType.id}`}
                      className={classes.link}
                    >
                      {locationType.id}
                    </Link>
                  </TableCell>
                  <TableCell>{locationType.locationTypeName}</TableCell>
                 </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
    </>
  )
}

export default LocationTypesScreen
