import { useState, useEffect, useCallback } from 'react'

const emptyMachineLocation = {
  locationName: '',
  corporatehq: false,
  locationSize: 0,
  isLocalPopulation: false,
  clothingType: 0,
  outdoorCompetition: 0,
  directCompetitor: 0,
  customerType: '',
  Canteen: '',
  machinePosition: null,
  homeOfficeDays: 0,
  locationCategoryId: '',
  locationTypeId: '',
  geographicalLocationId: '',
}

const useEditMachineLocation = (initialMachineLocation) => {
  const [machineLocation, setMachineLocation] = useState(initialMachineLocation || emptyMachineLocation)
  const resetValues = useCallback(() => {
    setMachineLocation({
      locationName: initialMachineLocation.locationName,
      corporatehq: initialMachineLocation.corporatehq,
      locationSize: initialMachineLocation.locationSize,
      isLocalPopulation: initialMachineLocation.isLocalPopulation,
      clothingType: initialMachineLocation.clothingType,
      outdoorCompetition: initialMachineLocation.outdoorCompetition,
      directCompetitor: initialMachineLocation.directCompetitor,
      customerType: initialMachineLocation.customerType,
      Canteen: initialMachineLocation.Canteen,
      machinePosition: initialMachineLocation.machinePosition,
      homeOfficeDays: initialMachineLocation.homeOfficeDays,
      locationCategoryId: initialMachineLocation.locationCategoryId,
      locationTypeId: initialMachineLocation.locationTypeId,
      geographicalLocationId: initialMachineLocation.geographicalLocationId,
    })
  }, [initialMachineLocation])
  useEffect(() => {
    if (initialMachineLocation) {
      resetValues()
    }
  }
  , [initialMachineLocation, resetValues])

  const handleMachineLocationValueChange = (key, value) => {
    setMachineLocation((prev) => ({
      ...prev,
      [key]: value,
    }))
  }
  return {
    machineLocation,
    handleMachineLocationValueChange,
  }
}


const emptyLocationCategory = {
  locationCategoryName: '',
}

const useEditLocationCategory = (initialLocationCategory) => {
  const [locationCategory, setLocationCategory] = useState(initialLocationCategory || emptyLocationCategory)
  const resetValues = useCallback(() => {
    setLocationCategory({
      locationCategoryName: initialLocationCategory.locationCategoryName,
    })
  }, [initialLocationCategory])

  useEffect(() => {
    if (initialLocationCategory) {
      resetValues()
    }
  }, [initialLocationCategory, resetValues])

  const handleLocationCategoryValueChange = (key, value) => {
    setLocationCategory((prev) => ({
      ...prev,
      [key]: value,
    }))
  }
  return {
    locationCategory,
    handleLocationCategoryValueChange,
  }
}

const emptyLocationType = {
  locationTypeName: '',
}
const useEditLocationType = (initialLocationType) => {
  const [locationType, setLocationType] = useState(initialLocationType || emptyLocationType)
  const resetValues = useCallback(() => {
    setLocationType({
      locationTypeName: initialLocationType.locationTypeName,
    })
  }, [initialLocationType])

  useEffect(() => {
    if (initialLocationType) {
      resetValues()
    }
  }, [initialLocationType, resetValues])

  const handleLocationTypeValueChange = (key, value) => {
    setLocationType((prev) => ({
      ...prev,
      [key]: value,
    }))
  }  

  return {
    locationType,
    handleLocationTypeValueChange,
  }
}

const emptyGeographicalLocation = {
  geographicalLocationName: '',
}
const useEditGeographicalLocation = (initialGeographicalLocation) => {
  const [geographicalLocation, setGeographicalLocation] = useState(initialGeographicalLocation || emptyGeographicalLocation)
  const resetValues = useCallback(() => {
    setGeographicalLocation({
      geographicalLocationName: initialGeographicalLocation.geographicalLocationName,
    })
  }, [initialGeographicalLocation])

  useEffect(() => {
    if (initialGeographicalLocation) {
      resetValues()
    }
  }, [initialGeographicalLocation, resetValues])

  const handleGeographicalLocationValueChange = (key, value) => {
    setGeographicalLocation((prev) => ({
      ...prev,
      [key]: value,
    }))
  }  

  return {
    geographicalLocation,
    handleGeographicalLocationValueChange,
  }
}
export { useEditMachineLocation }
export { useEditLocationCategory }
export { useEditLocationType }
export { useEditGeographicalLocation }

