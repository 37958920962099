import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import {
  CreateDiscountDocument,
  DiscountFields,
  DiscountsDocument,
  DiscountType,
} from '../generated'
import Dialog from '../components/dialog'
import { DiscountEditor } from './DiscountEditor'

const CreateDiscount = ({
  open,
  setOpen,
  activeOnly,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  activeOnly: boolean
}) => {
  const [createDiscount] = useMutation(CreateDiscountDocument)

  const [discountConfig, setDiscountConfig] = useState<DiscountFields>({
    code: '',
    reduction: 0,
    type: DiscountType.Percentage,
    active: true,
    singleUse: false,
    validFrom: new Date(),
    validTo: new Date(),
    validMachineIds: [],
  })

  const handleSubmit = () => {
    createDiscount({
      variables: { input: discountConfig },
      refetchQueries: [{ query: DiscountsDocument, variables: { activeOnly } }],
    }).then(() => setOpen(false))
  }

  const handleClose = () => setOpen(false)

  const handleChange = <K extends keyof DiscountFields>(
    field: K,
    value: DiscountFields[K]
  ) =>
    setDiscountConfig((prevDiscount) => ({
      ...prevDiscount,
      [field]: value,
    }))

  return (
    <Dialog
      title="Create Discount"
      open={open}
      onClose={handleClose}
      description="Discounts codes are case sensitive. Single use means that the discount
        only can be used one time. If no code is supplied, a random 5 character code will be generated."
      handleSubmit={handleSubmit}
      primaryActionText="Add"
    >
      <DiscountEditor
        handleChange={handleChange}
        discountConfig={discountConfig}
      />
    </Dialog>
  )
}

export default CreateDiscount
