import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { createClient } from './createClient'
import { ApolloProvider } from '@apollo/client'
import { useAuth } from './context/auth'
import Spinner from './components/spinner'

export const ApolloWrapper = () => {
  const [apiKey, setApiKey] = useState<string | null>(null)
  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      ;(async () => {
        const idToken = await user.getIdToken()

        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URI}/api/auth/get-api-token`,
            { headers: { Authorization: idToken } }
          )
          const data = await res.json()
          setApiKey(data.apiKey)
        } catch (_e) {}
      })()
    } else {
      setApiKey(null)
    }
  }, [user])

  return apiKey ? (
    <ApolloProvider client={createClient(apiKey)}>
      <Outlet />
    </ApolloProvider>
  ) : (
    <Spinner />
  )
}
