import React from 'react'

import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { MachinesDocument } from '../generated'

const useStyles = makeStyles({ formControl: { minWidth: 250 } })

const SelectMachine = ({
  machineId,
  setMachineId,
  allowAll,
}: {
  machineId: string
  setMachineId: React.Dispatch<React.SetStateAction<string>>
  allowAll?: boolean
}) => {
  const { loading, error, data } = useQuery(MachinesDocument, {
    variables: { activeOnly: true },
  })

  const classes = useStyles()

  if (loading || !data) return <p>Loading...</p>
  if (error) return <p>Error :( {JSON.stringify(error, null, 2)}</p>

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="select-machine">Select machine</InputLabel>
      <Select
        labelId="select-machine"
        id="select-machine"
        value={machineId}
        onChange={(e) => setMachineId(e.target.value as string)}
        defaultValue={0}
      >
        {allowAll && <MenuItem value={'ALL_MACHINES'}>Show all</MenuItem>}
        {data.machines
          .slice()
          .sort((a, b) => a.location.localeCompare(b.location))
          .map((machine) => (
            <MenuItem key={machine.id} value={machine.id}>
              {machine.location}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default SelectMachine
