import React from 'react'
import { useAuth } from '../context/auth'

import AppShell from '../AppShell'
import Spinner from '../components/spinner'

export const Protected = ({
  component: Component,
  ...rest
}: {
  component: () => JSX.Element
}) => {
  const { isLoggedIn } = useAuth()
  if (isLoggedIn === null)
    return (
      <AppShell>
        <Spinner />
      </AppShell>
    )
  if (!isLoggedIn) return null
  return (
    <AppShell>
      <Component {...rest} />
    </AppShell>
  )
}
