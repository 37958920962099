import React from 'react'
import { useEditGeographicalLocation } from './hooks.js'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, Link } from 'react-router-dom'
import Title from '../components/Title.js'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, TextField } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import {
    GeographicalLocationByIdDocument,
    AddNewGeographicalLocationDocument,
    UpdateGeographicalLocationDocument,
    RefetchGeographicalLocationsDocument
} from '../generated'
import { FormGroup } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2), // Set top margin
  },
}))

const GeographicalLocationScreen = () => {
  // Get path
  const { id } = useParams();
  console.log({id})
  const { data: geographicalLocationData } = useQuery(GeographicalLocationByIdDocument, {
    variables: { id },
  })
  const {
    handleGeographicalLocationValueChange,
    geographicalLocation,
  } = useEditGeographicalLocation(geographicalLocationData?.geographicalLocationById)
  
  const classes = useStyles()
  const [addNewGeographicalLocation] = useMutation(AddNewGeographicalLocationDocument)
  const [updateGeographicalLocation] = useMutation(UpdateGeographicalLocationDocument)
  const navigate = useNavigate()
  const handleGeographicalLocationSubmit = () => {
    if (id) {
        updateGeographicalLocation({
          variables: {
            input: {
              geographicalLocationId: id,
              geographicalLocationName: geographicalLocation.geographicalLocationName
            }
          },
          refetchQueries: [{ query: RefetchGeographicalLocationsDocument }],
        }).then((data) => navigate('/settings/3'))
    } else {
      addNewGeographicalLocation({
        variables: {
          input: {
            geographicalLocationName: geographicalLocation.geographicalLocationName
          }
        },
        refetchQueries: [{ query: RefetchGeographicalLocationsDocument }],
      }).then((data) => navigate('/settings/3'))
    }
    return true;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Link
            to={'/settings/3'}
            className={classes.backArrow}
          >
            &larr; Go back
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Title>Geographical Location</Title>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
          <TextField
            label="Geographical Location Name"
            variant="outlined"
            fullWidth
            value={geographicalLocation.geographicalLocationName}
            onChange={(e) => handleGeographicalLocationValueChange('geographicalLocationName', e.target.value)}
          />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.button} // Apply custom style
            color="primary"
            variant="contained"
            onClick={() => navigate('/settings/3')}
          >
            BACK
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button
            className={classes.button} // Apply custom style
            color="primary"
            variant="contained"
            onClick={handleGeographicalLocationSubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default GeographicalLocationScreen
