import React, { createContext, useContext, useEffect, useCallback } from 'react'

import {
  useSignInWithEmailAndPassword,
  useAuthState,
  useSignOut,
} from 'react-firebase-hooks/auth'
import firebase from '../firebase'
import { User } from 'firebase/auth'
import { useLocation, useNavigate } from 'react-router-dom'

type ContextValue = {
  isLoggedIn: boolean | null
  logIn: (email: string, password: string) => void
  logOut: () => void
  error: string | null
  user: User | null | undefined
}

export const AuthContext = createContext<ContextValue | undefined>(undefined)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const [signInWithEmailAndPassword, _user, _loading, signInError] =
    useSignInWithEmailAndPassword(firebase.auth)
  // console.log(_user, _loading);
  const [user, fetchingLoggedInState] = useAuthState(firebase.auth)
  const [signOut] = useSignOut(firebase.auth)
  const navigate = useNavigate()

  const buildRedirectUrl = useCallback(() => {
    if (location.pathname === '/') return '/login'
    return `/login?redirect_to=${
      location.pathname + location.search + location.hash
    }`
  }, [location])

  useEffect(() => {
    if (!fetchingLoggedInState && !user && location.pathname !== '/login') {
      navigate(buildRedirectUrl())
    }
  }, [fetchingLoggedInState, user, location.pathname, buildRedirectUrl, navigate])

  useEffect(() => {
    if (!fetchingLoggedInState && user && location.pathname === '/login') {
      const urlParams = new URLSearchParams(window.location.search)
      const redirectTo = urlParams.get('redirect_to')
      navigate(redirectTo ?? '/')
    }
  }, [fetchingLoggedInState, user, location.pathname, navigate])

  const logIn: ContextValue['logIn'] = async (email, password) => {
    signInWithEmailAndPassword(email, password)
  }

  const logOut: ContextValue['logOut'] = async () => signOut()

  const isLoggedIn = React.useMemo(
    () => (fetchingLoggedInState ? null : Boolean(user)),
    [fetchingLoggedInState, user]
  )
  const error = signInError?.toString() || null

  return (
    <AuthContext.Provider value={{ isLoggedIn, logIn, logOut, error, user }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const value = useContext(AuthContext)

  if (!value) {
    throw new Error('AuthContext must be used within an AuthProvider')
  }

  return value
}
