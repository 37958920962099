import React, { useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

interface SingleMachineQRCodeScreenProps {
  machineId: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    flexShrink: 0,
  },
  actionButtonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

const SingleMachineQRCodeScreen: React.FC<SingleMachineQRCodeScreenProps> = ({ machineId }) => {
  const classes = useStyles();
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const value = `https://veat.se/webapp/?${machineId}`;

  const downloadQRCode = () => {
    const canvas = qrCodeRef.current?.querySelector("canvas");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${machineId}-QRCode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const closeWindow = () => {
    window.close();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
      <div ref={qrCodeRef} style={{ flex: '1 0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <QRCodeCanvas
            onClick={downloadQRCode}
            size={1024}
            style={{ height: "auto", maxWidth: "100%", width: "100%", cursor: "pointer" }}
            value={value}
        />
      </div>
    </div>
  );
};

export default SingleMachineQRCodeScreen;