import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  actionButtonRow: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
})

const MyDialog = ({
  title,
  description,
  open,
  onClose,
  handleSubmit,
  children,
  primaryActionText = 'Submit',
  cancelActionText = 'Cancel',
  actions,
  primaryActionDisabled,
}: {
  title: string
  description?: string
  open: boolean
  onClose: () => void
  handleSubmit?: () => void
  children?: React.ReactNode
  primaryActionText?: string
  cancelActionText?: string
  actions?: React.ReactNode
  primaryActionDisabled?: boolean
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}*</DialogTitle>
      <DialogContent>
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions className={classes.actionButtonRow}>
        {actions ?? (
          <div className={classes.actionButtonRow}>
            <Button onClick={onClose}>{cancelActionText}</Button>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={primaryActionDisabled}
            >
              {primaryActionText}
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default MyDialog
