import React from 'react'
import { Link } from 'react-router-dom'
import { useEditLocationCategory } from './hooks.js'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import Title from '../components/Title.js'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, TextField } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import {
    LocationCategoryByIdDocument,
    AddNewLocationCategoryDocument,
    UpdateLocationCategoryDocument,
    RefetchLocationCategoriesDocument
} from '../generated'
import { FormGroup } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2), // Set top margin
  },
}))

const LocationCategoryScreen = () => {
  // Get path
  const { id } = useParams();
  console.log({id})
  const { data: locationCategoryData } = useQuery(LocationCategoryByIdDocument, {
    variables: { id },
  })
  const {
    handleLocationCategoryValueChange,
    locationCategory,
  } = useEditLocationCategory(locationCategoryData?.locationCategoryById)
  
  const classes = useStyles()
  const [addNewLocationCategory] = useMutation(AddNewLocationCategoryDocument)
  const [updateLocationCategory] = useMutation(UpdateLocationCategoryDocument)
  const navigate = useNavigate()
  const handleLocationCategorySubmit = () => {
    if (id) {
        updateLocationCategory({
          variables: {
            input: {
              locationCategoryId: id,
              locationCategoryName: locationCategory.locationCategoryName
            }
          },
          refetchQueries: [{ query: RefetchLocationCategoriesDocument }],
        }).then((data) => navigate('/settings/locationcategories'))
    } else {
      addNewLocationCategory({
        variables: {
          input: {
            locationCategoryName: locationCategory.locationCategoryName
          }
        },
        refetchQueries: [{ query: RefetchLocationCategoriesDocument }],
      }).then((data) => navigate('/settings/1'))
    }
    return true;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Link
            to={'/settings/1'}
            className={classes.backArrow}
          >
            &larr; Go back
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Title>Location Category</Title>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
          <TextField
            label="Location Category Name"
            variant="outlined"
            fullWidth
            value={locationCategory.locationCategoryName}
            onChange={(e) => handleLocationCategoryValueChange('locationCategoryName', e.target.value)}
          />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.button} // Apply custom style
            color="primary"
            variant="contained"
            onClick={() => navigate('/settings/1')}
          >
            BACK
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button
            className={classes.button} // Apply custom style
            color="primary"
            variant="contained"
            onClick={handleLocationCategorySubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default LocationCategoryScreen
