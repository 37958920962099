import { useState, useEffect } from 'react'

export const useStatefulSearchParam = ({
  key,
  defaultValue,
}: {
  key: string
  defaultValue: string
}) => {
  const [value, setValue] = useState(
    new URLSearchParams(window.location.search).get(key) || defaultValue
  )

  useEffect(() => {
    const q = new URLSearchParams(window.location.search)
    q.set(key, value)
    window.history.replaceState(null, '', '?' + q.toString())
  }, [value, key])

  return [value, setValue] as const
}

export const useWorkingValue = <T>(defaultValue: T) => {
  const [workingValue, setWorkingValue] = useState<T>(defaultValue)

  const handleChange = <K extends keyof T>(field: K, value: T[K]) =>
    setWorkingValue((prevValue) => ({ ...prevValue, [field]: value }))

  const resetWorkingValue = () => setWorkingValue(defaultValue)

  return [workingValue, handleChange, resetWorkingValue] as const
}

export const useSyncStateToSearchParams = (state: { [key: string]: any }) => {
  useEffect(() => {
    const params = new URLSearchParams()
    for (let [key, value] of Object.entries(state)) {
      params.set(key, value)
    }
    window.history.replaceState(null, '', `?${params.toString()}`)
  }, [state])
}
