import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'

import Title from '../components/Title'
import Spinner from '../components/spinner'
import { MachinesDocument } from '../generated'

const useStyles = makeStyles((theme) => ({
  tableHead: { fontWeight: 'bold' },
  link: { '&:hover': { cursor: 'pointer', color: theme.palette.primary.main } },
}))

const MachinesScreen = () => {
  const classes = useStyles()
  const [activeOnly, setActiveOnly] = useState(true)
  const { loading, error, data, refetch } = useQuery(MachinesDocument, {
    variables: { activeOnly },
  })

  useEffect(() => {
    if (data) {
      refetch({ activeOnly })
    }
  }, [activeOnly, data, refetch])

  if (loading) return <Spinner />
  if (error) return <p>Error {JSON.stringify(error, null, 2)}</p>

  const tableHeaders = ['ID', 'Machine name', ...(!activeOnly ? ['Active'] : [])]

  const sortMachines = (a, b) => {
    const activeDiff = (b.active ? 1 : 0) - (a.active ? 1 : 0)
    if (activeDiff !== 0) return activeDiff
    return a.location.localeCompare(b.location)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Title>Machines</Title>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={() =>
                  setActiveOnly((prevActiveOnly) => !prevActiveOnly)
                }
                value={activeOnly}
                checked={activeOnly}
              />
            }
            label="Active Only"
            labelPlacement="left"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((h) => (
                <TableCell className={classes.tableHead}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.machines
              .slice()
              .sort(sortMachines)
              .map((machine) => (
                <TableRow key={machine.id}>
                  <TableCell>
                    <Link
                      to={`/machines/${machine.id}`}
                      className={classes.link}
                    >
                      {machine.id}
                    </Link>
                  </TableCell>
                  <TableCell>{machine.location}</TableCell>
                  {!activeOnly && (
                    <TableCell>{machine.active ? 'TRUE' : 'FALSE'}</TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
    </>
  )
}

export default MachinesScreen
