import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/client'

import { makeStyles } from '@material-ui/core/styles'

import { MenuItemEditor } from './MenuItemEditor'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'

import Paper from '@material-ui/core/Paper'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Dialog from '../components/dialog'

import Grid from '@material-ui/core/Grid'

import Title from '../components/Title'
import Spinner from '../components/spinner'
import SelectMachine from '../components/selectMachine'
import { useStatefulSearchParam, useWorkingValue } from '../hooks'
import { getTextForLocale } from '../helpers'
import {
  CreateMenuItemDocument,
  CreateMenuItemMutation,
  DeleteMenuItemDocument,
  DeleteMenuItemMutation,
  ImageType,
  MenuItemsByMachineIdDocument,
  MenuItemsByMachineIdQuery,
  MutationUpdateMenuItemArgs,
  QueryMenuItemsByMachineIdArgs,
  UpdateMenuItemDocument,
  UpdateMenuItemMutation,
} from '../generated'

const useStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  toRight: { padding: theme.spacing(2), paddingLeft: '0' },
  toLeft: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingRight: '0',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  spinnerContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background: 'rgba(0,0,0,0.1)',
  },
  productContainer: { display: 'flex', alignItems: 'center' },
  overrideContainer: { display: 'flex', alignItems: 'center' },
  categoryLabel: { fontWeight: 600, color: theme.palette.primary.main },
  thumbnail: { height: 30, width: 'auto', paddingRight: 20 },
}))

type QueriedMenuItem = MenuItemsByMachineIdQuery['menuItemsByMachineId'][0]

const MenuItemRow = ({
  menuItem,
  machineId,
  availableProductIds,
}: {
  menuItem: QueriedMenuItem
  machineId: string
  availableProductIds: string[]
}) => {
  const classes = useStyles()
  const name = getTextForLocale(menuItem.product).name
  const imageUrl = (
    menuItem.product.images.find((img) => img.imageType === ImageType.Splash) ||
    menuItem.product.images[0]
  )?.imageUrl
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)

  const [workingMenuItem, onChange] = useWorkingValue({
    overridePrice: menuItem.overridePrice ?? null,
  })

  const [deleteMenuItem] = useMutation<DeleteMenuItemMutation>(
    DeleteMenuItemDocument
  )
  const [updateMenuItem] = useMutation<UpdateMenuItemMutation>(
    UpdateMenuItemDocument
  )

  const handleDeleteSubmit = async () => {
    await deleteMenuItem({
      variables: { id: menuItem.id },
      refetchQueries: [
        {
          query: MenuItemsByMachineIdDocument,
          variables: {
            input: { machineId, includeUnavailable: true },
          } as QueryMenuItemsByMachineIdArgs,
        },
      ],
    })
    setDeleteDialogOpen(false)
  }

  const handleEditSubmit = async () => {
    await updateMenuItem({
      variables: {
        input: { ...workingMenuItem, id: menuItem.id },
      } as MutationUpdateMenuItemArgs,
      refetchQueries: [
        {
          query: MenuItemsByMachineIdDocument,
          variables: {
            input: { machineId, includeUnavailable: true },
          } as QueryMenuItemsByMachineIdArgs,
        },
      ],
    })
    setEditDialogOpen(false)
  }

  return (
    <TableRow hover>
      <TableCell>
        <div className={classes.productContainer}>
          <img src={imageUrl} alt={name ?? ''} className={classes.thumbnail} />
          {name}
        </div>
      </TableCell>
      <TableCell>
        {menuItem.stocks.map((stock) => stock.channel + ' ')}
      </TableCell>
      <TableCell>
        <div className={classes.categoryLabel}>{menuItem.product.category}</div>
      </TableCell>
      <TableCell>{menuItem.overridePrice}</TableCell>
      <TableCell align="right">
        <IconButton onClick={() => setEditDialogOpen(true)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => setDeleteDialogOpen(true)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <Dialog
        title="Edit menu item"
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        handleSubmit={() => handleEditSubmit()}
        primaryActionText="Update"
      >
        <MenuItemEditor
          menuItem={workingMenuItem}
          onChange={onChange}
          availableProductIds={availableProductIds}
        />
      </Dialog>
      <Dialog
        title="Are you sure?"
        open={deleteDialogOpen}
        description={`Are you sure you want to delete the menuitem with product 
  ${getTextForLocale(menuItem.product).name}${
          menuItem?.stocks
            ? ` on channels ${menuItem.stocks.map((s) => s.channel)}`
            : ''
        }`}
        onClose={() => setDeleteDialogOpen(false)}
        handleSubmit={() => deleteDialogOpen && handleDeleteSubmit()}
        primaryActionText="Delete"
      />
    </TableRow>
  )
}

const MenuItemsScreen = () => {
  const classes = useStyles()
  const [machineId, setMachineId] = useStatefulSearchParam({
    key: 'machineId',
    defaultValue: '96bea44e-7c42-45d9-897d-118fcb8274b7',
  })
  const { loading, error, data } = useQuery<MenuItemsByMachineIdQuery>(
    MenuItemsByMachineIdDocument,
    {
      variables: {
        input: { machineId, includeUnavailable: true },
      } as QueryMenuItemsByMachineIdArgs,
    }
  )
  const [createMenuItemDialogOpen, setCreateMenuItemDialogOpen] =
    useState(false)
  const [workingMenuItem, setWorkingMenuItem, resetWorkingValue] =
    useWorkingValue({
      overridePrice: null,
      productId: '',
    })
  const [createMenuItem] = useMutation<CreateMenuItemMutation>(
    CreateMenuItemDocument
  )

  if (loading || !data) return <Spinner />

  const handleCreateMenuItem = async () => {
    await createMenuItem({
      variables: { input: { machineId, ...workingMenuItem } },
      refetchQueries: [
        {
          query: MenuItemsByMachineIdDocument,
          variables: { input: { machineId, includeUnavailable: true } },
        },
      ],
    })
    setCreateMenuItemDialogOpen(false)
    resetWorkingValue()
  }

  const renderMenuItems = () => {
    if (loading || !data) return <Spinner />
    if (error)
      return (
        <TableRow>
          <TableCell>Error :({JSON.stringify(error, null, 2)}</TableCell>
        </TableRow>
      )

    return data?.menuItemsByMachineId.map((menuItem) => (
      <MenuItemRow
        key={menuItem.id}
        menuItem={menuItem}
        machineId={machineId}
        availableProductIds={data?.menuItemsByMachineId.map(
          (m) => m.product.id
        )}
      />
    ))
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Title>Menu Items</Title>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.toLeft}>
            <SelectMachine machineId={machineId} setMachineId={setMachineId} />
          </div>
        </Grid>
      </Grid>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Channels</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Override Price</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderMenuItems()}</TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {createMenuItemDialogOpen && (
        <Dialog
          title="Create Menu Item"
          open={createMenuItemDialogOpen}
          onClose={() => setCreateMenuItemDialogOpen(false)}
          handleSubmit={handleCreateMenuItem}
          primaryActionText="Add"
        >
          <MenuItemEditor
            menuItem={workingMenuItem}
            onChange={setWorkingMenuItem}
            availableProductIds={data?.menuItemsByMachineId.map(
              (m) => m.product.id
            )}
            editableProductId={true}
          />
        </Dialog>
      )}

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.toLeft}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setCreateMenuItemDialogOpen(true)}
            >
              + ADD MENU ITEM
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default MenuItemsScreen
