import React from 'react'
import { Link } from 'react-router-dom'
import { useEditMachineLocation } from './hooks.js'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import Title from '../components/Title.js'
import { makeStyles } from '@material-ui/core/styles'
import {
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Button,
  Grid,
  TextField,
  Select,
  Checkbox,
} from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import {
    MachineLocationByIdDocument,
    AddNewMachineLocationDocument,
    UpdateMachineLocationDocument,
    RefetchMachineLocationsDocument,
    LocationCategoriesDocument,
    LocationTypesDocument,
    GeographicalLocationsDocument,
} from '../generated'

const locationSizes = [
  { value: 1, text: '50 - 100' },
  { value: 2, text: '100 - 150' },
  { value: 3, text: '150 - 200' },
  { value: 4, text: '200 - 250' },
  { value: 5, text: '250+' },
];

const clothingTypes = [
  { value: 1, text: 'Casual' },
  { value: 2, text: 'Formal-Casual' },
  { value: 3, text: 'Formal' },
];

const outdoorCompetitionTypes = [
  { value: 1, text: 'Low Competition' },
  { value: 2, text: 'Medium Competition' },
  { value: 3, text: 'High Competition' },
];

const directCompetitorOptions = [
  { value: 1, text: 'Yes' },
  { value: 0, text: 'No' },
];

const canteenOptions = [
  { value: 'No', text: 'No canteen' },
  { value: 'Poor', text: 'Not well attended and high prices' },
  { value: 'Good', text: 'Well attended and good prices' },
  { value: 'Restaurant', text: 'Restaurant"' },
];

const customerTypeOptions = [
  { value: 'Captive', text: 'Only the people that work there can access the machine' },
  { value: 'Mixed', text: 'Mostly people that work there can access the machine but is also open to the public' },
  { value: 'Open', text: 'Completly open to the public, not in an office' },
];

// asile, lobby, break room or other. The place where the machine is placed whithin the location.
const machinePositions = [
  { value: 'aisle', text: 'Aisle' },
  { value: 'lobby', text: 'Lobby' },
  { value: 'break room', text: 'Break Room' },
  { value: 'other', text: 'Other' },
];

const homeOfficeDaysOptions = [
  { value: 1, text: '1 day' },
  { value: 2, text: '2 days' },
  { value: 3, text: '3 days' },
  { value: 4, text: '4 days' },
  { value: 5, text: '5 days' },
];

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2), // Set top margin
  },
}))

const MachineLocationScreen = () => {
  // Get path
  const { id } = useParams();
  const { data: machineLocationData } = useQuery(MachineLocationByIdDocument, {
    variables: { id },
  })
  const { data: locationCategoriesData } = useQuery(LocationCategoriesDocument)
  const { data: locationTypesData } = useQuery(LocationTypesDocument)
  const { data: geographicalLocationsData } = useQuery(GeographicalLocationsDocument)

  const {
    handleMachineLocationValueChange,
    machineLocation,
  } = useEditMachineLocation(machineLocationData?.machineLocationById)

  
  const classes = useStyles()
  const [addNewMachineLocation] = useMutation(AddNewMachineLocationDocument)
  const [updateMachineLocation] = useMutation(UpdateMachineLocationDocument)
  const navigate = useNavigate()
  const handleMachineLocationSubmit = () => {
    if (id) {
        updateMachineLocation({
          variables: {
            input: {
              machineLocationId: id,
              locationName: machineLocation.locationName,
              corporatehq: machineLocation.corporatehq,
              locationSize: machineLocation.locationSize,
              isLocalPopulation: machineLocation.isLocalPopulation,
              clothingType: machineLocation.clothingType,
              outdoorCompetition: machineLocation.outdoorCompetition,
              directCompetitor: machineLocation.directCompetitor,
              customerType: machineLocation.customerType,
              Canteen: machineLocation.Canteen,
              machinePosition: machineLocation.machinePosition,
              homeOfficeDays: machineLocation.homeOfficeDays,        
              locationCategoryId: machineLocation.locationCategoryId,
              locationTypeId: machineLocation.locationTypeId,
              geographicalLocationId: machineLocation.geographicalLocationId,
            }
          },
          refetchQueries: [{ query: RefetchMachineLocationsDocument }],
        }).then((data) => navigate('/settings/machinelocations'))
    } else {
      addNewMachineLocation({
        variables: {
          input: {
            locationName: machineLocation.locationName,
            corporatehq: machineLocation.corporatehq,
            locationSize: machineLocation.locationSize,
            isLocalPopulation: machineLocation.isLocalPopulation,
            clothingType: machineLocation.clothingType,
            outdoorCompetition: machineLocation.outdoorCompetition,
            directCompetitor: machineLocation.directCompetitor,
            customerType: machineLocation.customerType,
            Canteen: machineLocation.Canteen,
            machinePosition: machineLocation.machinePosition,
            homeOfficeDays: machineLocation.homeOfficeDays,
            locationCategoryId: machineLocation.locationCategoryId,
            locationTypeId: machineLocation.locationTypeId,
            geographicalLocationId: machineLocation.geographicalLocationId,            
          }
        },
        refetchQueries: [{ query: RefetchMachineLocationsDocument }],
      }).then((data) => navigate('/settings/machinelocations'))
    }
    return true;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Link
            to={'/settings/0'}
            className={classes.backArrow}
          >
            &larr; Go back
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Title>Location</Title>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
          <TextField
            label="Location Name"
            variant="outlined"
            fullWidth
            value={machineLocation.locationName}
            onChange={(e) => handleMachineLocationValueChange('locationName', e.target.value)}
          />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="location-category-label">Location Category</InputLabel>
              <Select
                labelId="location-category-label"
                value={machineLocation.locationCategoryId || ''}
                onChange={(e) => handleMachineLocationValueChange('locationCategoryId', e.target.value)}
                label="Location Category"
              >
                {locationCategoriesData?.locationCategories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.locationCategoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="location-type-label">Location Type</InputLabel>
              <Select
                labelId="location-type-label"
                value={machineLocation.locationTypeId || ''}
                onChange={(e) => handleMachineLocationValueChange('locationTypeId', e.target.value)}
                label="Location Type"
              >
                {locationTypesData?.locationTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.locationTypeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="geographical-location-label">Geographical Location</InputLabel>
              <Select
                labelId="geographical-location-label"
                value={machineLocation.geographicalLocationId || ''}
                onChange={(e) => handleMachineLocationValueChange('geographicalLocationId', e.target.value)}
                label="Geographical Location"
              >
                {geographicalLocationsData?.geographicalLocations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.geographicalLocationName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={machineLocation.corporatehq || false}
                  onChange={(e) => handleMachineLocationValueChange('corporatehq', e.target.checked)}
                  color="primary"
                />
              }
              label="Corporate HQ"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
        <FormGroup>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="location-size-label">Location Size</InputLabel>
            <Select
              labelId="location-size-label"
              value={machineLocation.locationSize || 0}
              onChange={(e) => handleMachineLocationValueChange('locationSize', parseInt(e.target.value))}
              label="Location Size"
            >
              {locationSizes.map((size) => (
                <MenuItem key={size.value} value={size.value}>
                  {size.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={machineLocation.isLocalPopulation || false}
                  onChange={(e) => handleMachineLocationValueChange('isLocalPopulation', e.target.checked)}
                  color="primary"
                />
              }
              label="Is Local Population"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="clothing-type-label">Clothing Type</InputLabel>
              <Select
                labelId="clothing-type-label"
                value={machineLocation.clothingType || 0}
                onChange={(e) => handleMachineLocationValueChange('clothingType', parseInt(e.target.value))}
                label="Clothing Type"
              >
                {clothingTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="outdoor-competition-label">Outdoor Competition</InputLabel>
              <Select
                labelId="outdoor-competition-label"
                value={machineLocation.outdoorCompetition || 0 }
                onChange={(e) => handleMachineLocationValueChange('outdoorCompetition', parseInt(e.target.value))}
                label="Outdoor Competition"
              >
                {outdoorCompetitionTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="direct-competitor-label">Direct Competitor</InputLabel>
              <Select
                labelId="direct-competitor-label"
                value={machineLocation.directCompetitor || 0 }
                onChange={(e) => handleMachineLocationValueChange('directCompetitor', parseInt(e.target.value))}
                label="Direct Competitor"
              >
                {directCompetitorOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant='outlined' fullWidth>
            <InputLabel id="customer-type-label">Customer Type</InputLabel>
            <Select
              labelId="customer-type-label"
              value={machineLocation.customerType || ''}
              onChange={(e) => handleMachineLocationValueChange('customerType', e.target.value)}
              label="Customer Type"
            >
              {customerTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="canteen-label">Canteen</InputLabel>
            <Select
              labelId="canteen-label"
              value={machineLocation.Canteen || ''}
              onChange={(e) => handleMachineLocationValueChange('Canteen', e.target.value)}
              label="Canteen"
            >
              {canteenOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="machine-position-label">Machine Position</InputLabel>
            <Select
              labelId="machine-position-label"
              value={machineLocation.machinePosition || null}
              onChange={(e) => handleMachineLocationValueChange('machinePosition', e.target.value)}
              label="Machine Position"
            >
              {machinePositions.map((position) => (
                <MenuItem key={position.value} value={position.value}>
                  {position.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="home-office-days-label">Home Office Days</InputLabel>
            <Select
              labelId="home-office-days-label"
              value={machineLocation.homeOfficeDays || ''}
              onChange={(e) => handleMachineLocationValueChange('homeOfficeDays', e.target.value)}
              label="Home Office Days"
            >
              {homeOfficeDaysOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>          
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.button} // Apply custom style
            color="primary"
            variant="contained"
            onClick={() => navigate('/settings/0')}
          >
            BACK
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button
            className={classes.button} // Apply custom style
            color="primary"
            variant="contained"
            onClick={handleMachineLocationSubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default MachineLocationScreen
