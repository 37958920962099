import React, { useState } from 'react'

import { gql, useLazyQuery, useMutation } from '@apollo/client'

import Dialog from '../components/dialog'
import Autocomplete from '@material-ui/lab/Autocomplete'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

import { makeStyles } from '@material-ui/core/styles'

import { callIfValueIsNumericOrEmpty, getTextForLocale } from '../helpers'
import {
  MachineByIdDocument,
  MenuItem as MenuItemType,
  Product,
  ProductText,
} from '../generated'

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexWrap: 'wrap' },
  textField: { marginTop: theme.spacing(2), marginRight: theme.spacing(1) },
  formControl: { marginRight: theme.spacing(1), minWidth: 450 },
}))

const CREATE_STOCK_INPUT = gql`
  mutation CreateStock($menuItemId: ID!, $channel: Int!, $quantity: Int!) {
    createStock(
      input: { menuItemId: $menuItemId, channel: $channel, quantity: $quantity }
    ) {
      channel
    }
  }
`

const GET_MENUITEM_BY_ID = gql`
  query MenuItemById($menuItemId: ID!) {
    menuItemById(menuItemId: $menuItemId) {
      product {
        id
      }
    }
  }
`

const INVENTORY_STOCK_TRANSACTION_V2 = gql`
  mutation InventoryStockTransactionV2($input: StockTransactionV2InventoryInput!) {
    inventoryStockTransactionV2(input: $input) {
      balance
    }
  }
`;

const SelectProduct = <
  PartialProduct extends DeepPartial<Product> & {
    texts: Partial<ProductText>[]
  },
  MenuItemBase extends Pick<MenuItemType, 'id'> & {
    product: PartialProduct
  }
>({
  handleChange,
  selectedMenuItemId,
  menuItems,
}: {
  handleChange: (value: string) => void
  selectedMenuItemId: string
  menuItems: Array<MenuItemBase>
}) => {
  const classes = useStyles()

  if (!menuItems) return <p>Loading...</p>

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="menu-item-select-label">Select a menu item</InputLabel>
      <Select
        labelId="menu-item-select-label"
        id="select-product"
        value={selectedMenuItemId}
        onChange={(e) => handleChange(e.target.value as string)}
      >
        {menuItems
          .slice()
          .sort((a, b) =>
            (getTextForLocale(a.product).name || '') >
            (getTextForLocale(b.product).name || '')
              ? 1
              : -1
          )
          .map((menuItem) => (
            <MenuItem value={menuItem.id}>
              {getTextForLocale(menuItem.product).name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

type CreateInventoryProps<T> = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  machineId: string
  menuItems: Array<T>
  allChannels: Array<number>
  availableChannels: Array<number>
}

type InputType = {
  menuItemId: string
  quantity: number | null
  channels: Array<number>
}

export const CreateInventory = <
  PartialProduct extends DeepPartial<Product> & {
    texts: Partial<ProductText>[]
  },
  PartialMenuItem extends Pick<
    MenuItemType,
    'id' | 'availableFrom' | 'availableTo'
  > & { product: PartialProduct }
>({
  open,
  setOpen,
  machineId,
  menuItems,
  allChannels,
  availableChannels,
}: CreateInventoryProps<PartialMenuItem>) => {
  const classes = useStyles()
  const [addMenuItem] = useMutation(CREATE_STOCK_INPUT)
  const [stockTransactionV2Inventory] = useMutation(INVENTORY_STOCK_TRANSACTION_V2)
  const [getMenuItemById] = 
    useLazyQuery(GET_MENUITEM_BY_ID,{ onCompleted: (data) => {
      const updateData = {
        machineId,
        channel: inputs.channels[0],
        productId: data.menuItemById.product.id,
        balance: inputs.quantity,
      }
      stockTransactionV2Inventory({
        variables: {
          input: updateData
        }
      })
    }
  })
  const [inputs, setInputs] = useState<InputType>({
    menuItemId: '',
    quantity: 0,
    channels: [],
  })
  const selectedMenuItem = menuItems.find((m) => m.id === inputs.menuItemId)
  const shouldShowAllChannels =
    selectedMenuItem?.availableFrom || selectedMenuItem?.availableTo

  const handleChange = <T extends InputType, K extends keyof T>(
    key: K,
    value: T[K]
  ) => setInputs({ ...inputs, [key]: value })

  const handleSubmit = async () => {
    await Promise.all(
      inputs.channels.map((channel) =>
        addMenuItem({
          variables: {
            menuItemId: inputs.menuItemId,
            channel: channel,
            quantity: inputs.quantity ?? 0,
          },
          refetchQueries: [
            { query: MachineByIdDocument, variables: { id: machineId } },
          ],
        })
      )
    ).then(() => {
      getMenuItemById({ variables: { menuItemId: inputs.menuItemId }})
    })
    setOpen(false)
  }

  const candidateChannels = (
    shouldShowAllChannels ? allChannels : availableChannels
  )
    .slice()
    .sort((a, b) => b - a)

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      title="Create Inventory Item"
      handleSubmit={handleSubmit}
      primaryActionText="Add"
    >
      <form className={classes.root} noValidate autoComplete="off">
        <div>
          <SelectProduct
            selectedMenuItemId={inputs.menuItemId}
            handleChange={(value) => handleChange('menuItemId', value)}
            menuItems={menuItems}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <FormControl variant="filled" style={{ minWidth: 120 }}>
            <Autocomplete
              multiple
              options={candidateChannels}
              getOptionLabel={(option) => option.toString()}
              value={inputs.channels}
              size="small"
              onChange={(_, values) => handleChange('channels', values)}
              style={{ width: '300px', marginRight: '12px' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Channels" />
              )}
            />
          </FormControl>
          <TextField
            label="Quantity"
            className={classes.textField}
            value={inputs.quantity ?? ''}
            onChange={(e) =>
              callIfValueIsNumericOrEmpty(e.target.value, (value) =>
                handleChange('quantity', value)
              )
            }
            variant="outlined"
          />
        </div>
      </form>
    </Dialog>
  )
}
