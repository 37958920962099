import React from 'react'
import { useQuery } from '@apollo/client'

import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MuiMenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles'

import { callIfValueIsNumericOrEmpty, getTextForLocale } from '../helpers'
import {
  CreateMenuItemInput,
  ProductsDocument,
  ProductsQuery,
} from '../generated'
// import DateIntervalPicker from '../components/dateIntervalPicker'

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexWrap: 'wrap' },
  textField: { marginTop: theme.spacing(2), marginRight: theme.spacing(1) },
  formControl: { marginRight: theme.spacing(1), minWidth: 450 },
}))

const ProductSelector = ({
  handleChange,
  productId,
  availableProductIds,
}: {
  handleChange: <K extends keyof Omit<CreateMenuItemInput, 'machineId'>>(
    value: CreateMenuItemInput[K]
  ) => void
  productId: string
  availableProductIds: string[]
}) => {
  const { loading, error, data } = useQuery<ProductsQuery>(ProductsDocument, {
    variables: { activeOnly: true },
  })

  const classes = useStyles()

  if (loading || !availableProductIds || !data) return <p>Loading...</p>
  if (error) return <p>Error:({JSON.stringify(error, null, 2)}</p>

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="product-select-label">Select a product</InputLabel>
      <Select
        labelId="product-select-label"
        id="select-product"
        value={productId}
        onChange={(e) => handleChange(e.target.value)}
      >
        <MuiMenuItem value="" disabled />
        {data.products
          .filter((p) => !availableProductIds.includes(p.id))
          .sort((a, b) =>
            (getTextForLocale(a)?.name ?? '') >
            (getTextForLocale(b)?.name ?? '')
              ? 1
              : -1
          )
          .map((product) => (
            <MuiMenuItem key={product.id} value={product.id}>
              {getTextForLocale(product).name}
            </MuiMenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

type WorkingMenuItem = {
  overridePrice: number | null
  productId?: string
}

export const MenuItemEditor = <T extends WorkingMenuItem>({
  availableProductIds,
  menuItem,
  onChange,
  editableProductId,
}: {
  availableProductIds: string[]
  menuItem: T
  onChange: (field: keyof T, value: any) => void
  editableProductId?: boolean
}) => {
  const classes = useStyles()

  return (
    <FormControl component="fieldset">
      {editableProductId && (
        <ProductSelector
          productId={menuItem.productId ?? ''}
          handleChange={(value: string) => onChange('productId', value)}
          availableProductIds={availableProductIds}
        />
      )}
      <TextField
        label="Override price"
        className={classes.textField}
        value={menuItem.overridePrice}
        onChange={(e) =>
          callIfValueIsNumericOrEmpty(e.target.value, (value) =>
            onChange('overridePrice', value)
          )
        }
        variant="outlined"
        type="number"
        style={{ maxWidth: 200 }}
      />
      <Divider style={{ margin: '20px 0' }} />
    </FormControl>
  )
}
