import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  CardTerminalsDocument,
  SetMachineCardTerminalDocument,
} from '../../generated'
import { makeStyles } from '@material-ui/core/styles'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

const useStyles = makeStyles({ formControl: { minWidth: 250 } })

export const CardTerminals = ({
  pointOfServiceId,
  machineId,
}: {
  pointOfServiceId?: string | null
  machineId: string
}) => {
  const classes = useStyles()
  const { loading, error, data } = useQuery(CardTerminalsDocument)
  const [setCardTerminal] = useMutation(SetMachineCardTerminalDocument)
  const [terminalId, setTerminalId] = React.useState(pointOfServiceId || 0)

  const handleChange = async (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const newTerminalId = (event.target as HTMLInputElement).value
    await setCardTerminal({
      variables: {
        input: {
          machineId,
          pointOfServiceId: newTerminalId ? newTerminalId : null,
        },
      },
      refetchQueries: [{ query: CardTerminalsDocument }],
    })
    setTerminalId(newTerminalId)
  }

  if (loading || !data) return <p>Loading...</p>
  if (error) return <p>Error {JSON.stringify(error, null, 2)}</p>

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="select-terminal">Card Terminal</InputLabel>
      <Select
        id="select-terminal"
        value={terminalId}
        onChange={(e) => handleChange(e)}
      >
        <MenuItem value={0}>No card terminal</MenuItem>
        {data.cardTerminals.map((terminal) => (
          <MenuItem
            key={terminal.id}
            value={terminal.id}
            disabled={!!terminal.machine?.id}
          >
            {terminal.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
