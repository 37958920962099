import React from 'react'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import TodaysOrderValues from './TodaysOrderValue'
import TodaysOrderValueByMachine from './stats/TodaysOrderValueByMachine'

import Spinner from '../components/spinner'

import { useQuery } from '@apollo/client'
import { MachinesDocument } from '../generated'

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://veat.se/">
      Veat AB
    </Link>{' '}
    {new Date().getFullYear()}
  </Typography>
)

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  fixedHeight: { height: 240 },
  lowerHeight: { height: 160 },
}))

const TEST_MACHINE_ID = 'dd91b524-2ce6-44fa-bda3-7641f4becf36'

const Dashboard = () => {
  const classes = useStyles()

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)
  const lowerHeightpaper = clsx(classes.paper, classes.lowerHeight)

  const { loading, error, data } = useQuery(MachinesDocument, {
    variables: { activeOnly: true },
  })

  if (loading) return <Spinner />

  if (error) return <p>Error</p>

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            <TodaysOrderValues />
          </Paper>
        </Grid>

        {data.machines
          .filter((m) => m.id !== TEST_MACHINE_ID)
          .map((machine) => (
            <Grid item xs={12} sm={6} md={4} key={machine.id}>
              <Paper className={lowerHeightpaper}>
                <TodaysOrderValueByMachine machineId={machine.id} />
              </Paper>
            </Grid>
          ))}
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default Dashboard
