import React from 'react'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'

import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import { DiscountFields, DiscountType, MachinesDocument } from '../generated'

import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import { useQuery } from '@apollo/client'

interface DiscountWithOptionalCode extends Omit<DiscountFields, 'code'> {
  code?: string | null
}

export const DiscountEditor = ({
  discountConfig,
  handleChange,
  autoGenerateCode,
}: {
  discountConfig: DiscountWithOptionalCode
  handleChange: <K extends keyof DiscountFields>(
    key: K,
    value: DiscountFields[K]
  ) => void
  autoGenerateCode?: boolean
}) => {
  const { loading, data } = useQuery(MachinesDocument)

  const machineIdToLocation = data
    ? data.machines.reduce(
        (acc, { id, location }) => ({ ...acc, [id]: location }),
        {} as { [key: string]: string }
      )
    : null

  return (
    <Grid container spacing={2}>
      {!autoGenerateCode && (
        <Grid item xs={12}>
          <TextField
            style={{ minWidth: 200 }}
            value={discountConfig.code}
            label="Discount code"
            onChange={(e) => handleChange('code', e.target.value)}
          />
        </Grid>
      )}

      <Grid item>
        <FormControl>
          <InputLabel id="discount-type-select-readonly-label">Type</InputLabel>
          <Select
            labelId="discount-type-select-readonly-label"
            style={{ minWidth: 200 }}
            value={discountConfig.type}
            onChange={(e) =>
              handleChange('type', e.target.value as DiscountType)
            }
          >
            <MenuItem value={DiscountType.Percentage}>PERCENTAGE (%)</MenuItem>
            <MenuItem value={DiscountType.Amount}>AMOUNT (SEK)</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <TextField
          required
          type="number"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          style={{ width: 100 }}
          value={discountConfig.reduction}
          label="Reduction"
          onChange={(e) => handleChange('reduction', parseInt(e.target.value))}
          onBlur={(e) =>
            !e.target.validity.valid && handleChange('reduction', 0)
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          label="Single use"
          color="primary"
          control={
            <Switch
              color="primary"
              checked={discountConfig.singleUse}
              onChange={(e) => handleChange('singleUse', e.target.checked)}
            />
          }
        />
        <FormControlLabel
          label="Active"
          color="primary"
          control={
            <Switch
              color="primary"
              checked={discountConfig.active}
              onChange={(e) => handleChange('active', e.target.checked)}
            />
          }
        />
      </Grid>

      <Grid item xs={12} style={{ marginTop: '12px', marginBottom: '12px' }}>
        <Typography style={{ marginBottom: '6px' }}>
          Valid Machines (leave empty for all)
        </Typography>
        <Autocomplete
          multiple
          autoComplete
          autoHighlight
          disableCloseOnSelect
          filterSelectedOptions
          loading={loading}
          loadingText="Loading..."
          options={
            data?.machines
              .filter((m) => m.active)
              .sort((a, b) => a.location.localeCompare(b.location))
              .map((m) => m.id) ?? []
          }
          getOptionLabel={(machineId) => machineIdToLocation?.[machineId] ?? ''}
          value={discountConfig.validMachineIds}
          onChange={(_, values) => handleChange('validMachineIds', values)}
          style={{ maxWidth: '520px', marginRight: '32px' }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography>Valid From</Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            style={{ marginRight: 20 }}
            disableToolbar
            variant="inline"
            format="dd/MM yyyy"
            margin="normal"
            value={discountConfig.validFrom}
            onChange={(d) => handleChange('validFrom', d)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            autoOk={true}
          />
          <KeyboardTimePicker
            margin="normal"
            ampm={false}
            value={discountConfig.validFrom}
            onChange={(d) => handleChange('validFrom', d)}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12}>
        <Typography>Valid To</Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            style={{ marginRight: 20 }}
            disableToolbar
            variant="inline"
            format="dd/MM yyyy"
            margin="normal"
            value={discountConfig.validTo}
            onChange={(d) => handleChange('validTo', d)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            autoOk={true}
          />
          <KeyboardTimePicker
            margin="normal"
            ampm={false}
            value={discountConfig.validTo}
            onChange={(d) => handleChange('validTo', d)}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  )
}
