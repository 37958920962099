import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { SendReceiptDocument } from '../generated'

const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))

const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const SendReceipt = ({ paymentId }: { paymentId: string }) => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [validation, setValidation] = useState('')
  const [sendEmail] = useMutation(SendReceiptDocument)

  useEffect(() => {
    setValidation('')
  }, [email])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!emailRegexp.test(email.toLowerCase())) {
      setValidation('Email is invalid')
      console.log('invalid')
    } else {
      sendEmail({ variables: { input: { paymentId, email } } }).then(() => {
        setEmail('')
        setValidation('Sent')
      })
    }
  }

  return (
    <>
      <h2>Send Receipt</h2>
      <form onSubmit={handleSubmit} style={{ display: 'block' }}>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          label="Email"
          className={classes.textField}
        />
        <Button variant="contained" color="primary" type="submit">
          Send
        </Button>
      </form>
      {validation && <p>{validation}</p>}
    </>
  )
}
