import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  loadingSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}))

const Spinner = () => {
  const classes = useStyles()
  return <CircularProgress size={24} className={classes.loadingSpinner} />
}

export default Spinner
