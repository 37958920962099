import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Title from '../components/Title'
import { format } from 'date-fns'
import { gql, useQuery } from '@apollo/client'

import { Link as LinkRouter } from 'react-router-dom'
import { afterTax } from '../helpers'

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
})

const GET_TODAYS_NET = gql`
  query TodaysNet {
    todaysNet
  }
`
const GET_TODAYS_GROSS = gql`
  query TodaysGross {
    todaysGross
  }
`

const TodaysOrderValues = () => {
  const classes = useStyles()
  const net = useQuery(GET_TODAYS_NET)
  const gross = useQuery(GET_TODAYS_GROSS)

  const renderSales = (sales, dataKey) => {
    if (sales.loading) return 'Loading'
    if (sales.error) return 'Error'
    return thousandSeparators(Math.round(afterTax(sales.data[dataKey])))
  }

  const thousandSeparators = (x) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  return (
    <React.Fragment>
      <Title>Gross / Net</Title>
      <Typography component="p" variant="h4">
        {renderSales(gross, 'todaysGross')} / {renderSales(net, 'todaysNet')}{' '}
        SEK
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {format(new Date(), "'On' EEEE dd MMMM")}
      </Typography>
      <div>
        <LinkRouter to={'/orders'} color="primary">
          View orders
        </LinkRouter>
      </div>
    </React.Fragment>
  )
}

export default TodaysOrderValues
