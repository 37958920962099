import { ChangeEventHandler, useState } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import Title from '../components/Title'
import { DiscountEditor } from './DiscountEditor'
import {
  BatchCreateDiscountsDocument,
  BatchCreateDiscountsMutation,
  CreateDiscountInput,
  DiscountType,
} from '../generated'
import * as Papa from 'papaparse'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useMutation } from '@apollo/client'
import Spinner from '../components/spinner'

const useStyles = makeStyles(() => ({
  buttonRow: { display: 'flex', columnGap: '12px' },
  error: { fontWeight: 700, color: 'red' },
  dataTable: { marginTop: '48px', maxWidth: '950px', width: 'min-content' },
  tableCell: { border: '1px solid #333', minWidth: '225px' },
  tableRowStripd: { '&:nth-of-type(odd)': { backgroundColor: '#f1f1f1' } },
}))

const FileUpload = ({
  onChange,
  onError,
}: {
  onChange: (data: Array<any>) => void
  onError: (error: string) => void
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0]
    if (file) {
      Papa.parse<File>(file, {
        encoding: 'ISO-8859-1',
        complete: (result) => {
          if (result.errors) {
            onError(result.errors.map((e) => e.message).join(', '))
          }
          if (result.data) {
            onChange(result.data)
          }
        },
        error: (e) => onError(e.message),
      })
    }
  }

  return (
    <>
      <input
        accept=".csv"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span">
          Upload
        </Button>
      </label>
    </>
  )
}

export const BatchGenerateScreen = () => {
  const classes = useStyles()
  const [discountConfig, setDiscountConfig] = useState<CreateDiscountInput>({
    reduction: 0,
    type: DiscountType.Percentage,
    active: true,
    singleUse: false,
    validFrom: new Date(),
    validTo: new Date(),
    validMachineIds: [],
  })
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<Array<Array<string>> | null>(null)
  const [dataEnrichedWithCodes, setDataEnrichedWithCodes] = useState(false)
  const [batchCreateDiscounts, { loading }] =
    useMutation<BatchCreateDiscountsMutation>(BatchCreateDiscountsDocument)

  const handleChange = React.useCallback(
    <K extends keyof CreateDiscountInput>(
      key: K,
      value: CreateDiscountInput[K]
    ) =>
      setDiscountConfig((prevDiscountConfig) => ({
        ...prevDiscountConfig,
        [key]: value,
      })),
    []
  )

  const rowIsNotEmpty = (row: Array<string>) => !row.every((cell) => !cell)

  const handleFileSelected = (data: Array<Array<string>>) => {
    setData(data.filter(rowIsNotEmpty))
    setDataEnrichedWithCodes(false)
  }

  const handleSubmit = async () => {
    if (data) {
      const result = await batchCreateDiscounts({
        variables: { input: { amount: data.length, config: discountConfig } },
      })
      const codes = result.data?.batchCreateDiscounts
      if (codes) {
        setData(
          (prevData) =>
            prevData?.map((d, idx) => [...d, codes[idx].code ?? '']) ?? null
        )
        setDataEnrichedWithCodes(true)
      }
    }
  }

  const downloadData = () => {
    if (!data) return
    const csv = data.reduce((csv, row) => csv + row.join(';') + '\r\n', '')
    const blob = new Blob([csv], { type: 'application/csv' })

    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'download.csv'
    anchor.click()
    window.URL.revokeObjectURL(url)
    anchor.remove()
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Title>Discounts</Title>
          <p>
            This tool allows you to generate a batch of discounts. Upload a CSV
            file, for instance with customer information, and for every row the
            tool will add an automatically generated discount code, in
            accordance with the settings below.
          </p>

          <p>
            Today, only .CSV files are supported as input. If you have an Excel
            file, please save it as CSV first.
            <a
              rel="noopener noreferrer"
              href="https://support.microsoft.com/en-us/office/save-a-workbook-to-text-format-txt-or-csv-3e9a9d6c-70da-4255-aa28-fcacf1f081e6"
              target="_blank"
            >
              https://support.microsoft.com/en-us/office/save-a-workbook-to-text-format-txt-or-csv-3e9a9d6c-70da-4255-aa28-fcacf1f081e6
            </a>
          </p>
        </Grid>

        <Grid item>
          <Typography gutterBottom>Config</Typography>
          <DiscountEditor
            discountConfig={discountConfig}
            handleChange={handleChange}
            autoGenerateCode
          />
        </Grid>
        <Grid item>
          <div className={classes.buttonRow}>
            <FileUpload
              onChange={handleFileSelected}
              onError={(e) => setError(e)}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={!data || dataEnrichedWithCodes}
              onClick={handleSubmit}
            >
              {loading ? <Spinner /> : 'Submit'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!dataEnrichedWithCodes}
              onClick={downloadData}
            >
              Download
            </Button>
          </div>
        </Grid>
      </Grid>
      {error && (
        <Grid item>
          <p className={classes.error}>{error}</p>
        </Grid>
      )}
      {data && (
        <Grid item className={classes.dataTable}>
          <Typography gutterBottom>Parsed data</Typography>
          <TableContainer>
            <Table>
              <TableBody>
                {data.map((row, idx) => (
                  <TableRow key={idx} className={classes.tableRowStripd}>
                    {row.map((cell, idx) => (
                      <TableCell key={idx} className={classes.tableCell}>
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  )
}
