import React from 'react'
import { useEditLocationType } from './hooks.js'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams, Link } from 'react-router-dom'
import Title from '../components/Title.js'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, TextField } from '@material-ui/core'

import {
    LocationTypeByIdDocument,
    AddNewLocationTypeDocument,
    UpdateLocationTypeDocument,
    RefetchLocationTypesDocument
} from '../generated'
import { FormGroup } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2), // Set top margin
  },
}))

const LocationTypeScreen = () => {
  // Get path
  const { id } = useParams();
  const { data: locationTypeData } = useQuery(LocationTypeByIdDocument, {
    variables: { id },
  })
  const {
    handleLocationTypeValueChange,
    locationType,
  } = useEditLocationType(locationTypeData?.locationTypeById)
  
  const classes = useStyles()
  const [addNewLocationType] = useMutation(AddNewLocationTypeDocument)
  const [updateLocationType] = useMutation(UpdateLocationTypeDocument)
  const navigate = useNavigate()
  const handleLocationTypeSubmit = () => {
    if (id) {
        updateLocationType({
          variables: {
            input: {
              locationTypeId: id,
              locationTypeName: locationType.locationTypeName
            }
          },
          refetchQueries: [{ query: RefetchLocationTypesDocument }],
        }).then((data) => navigate('/settings/2'))
    } else {
      addNewLocationType({
        variables: {
          input: {
            locationTypeName: locationType.locationTypeName
          }
        },
        refetchQueries: [{ query: RefetchLocationTypesDocument }],
      }).then((data) => navigate('/settings/2'))
    }
    return true;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Link
            to={'/settings/2'}
            className={classes.backArrow}
          >
            &larr; Go back
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Title>Location Type</Title>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
          <TextField
            label="Location Type Name"
            variant="outlined"
            fullWidth
            value={locationType.locationTypeName}
            onChange={(e) => handleLocationTypeValueChange('locationTypeName', e.target.value)}
          />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.button} // Apply custom style
            color="primary"
            variant="contained"
            onClick={() => navigate('/settings/2')}
          >
            BACK
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button
            className={classes.button} // Apply custom style
            color="primary"
            variant="contained"
            onClick={handleLocationTypeSubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default LocationTypeScreen
