import React, { useEffect, useState } from 'react'

import Dialog from '../../components/dialog'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

import { CopyMachineConfigInput } from '../../generated'

const useStyles = makeStyles({
  configContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  nameInput: { marginBottom: '8px' },
})

export const CopyMachineDialog = ({
  open,
  onOpen,
  onClose,
  onSubmit,
}: {
  open: boolean
  onOpen: () => void
  onClose: () => void
  onSubmit: (config: Omit<CopyMachineConfigInput, 'machineId'>) => void
}) => {
  const classes = useStyles()
  const [config, setConfig] = useState<
    Omit<CopyMachineConfigInput, 'machineId'>
  >({
    location: '',
    copyPaymentMethods: true,
    copyChannels: true,
    copyMenuItems: true,
    copyInventory: true,
  })
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setError(null)
  }, [config.location])

  useEffect(() => {
    if (
      (!config.copyChannels || !config.copyMenuItems) &&
      config.copyInventory
    ) {
      setConfig((prevConfig) => ({ ...prevConfig, copyInventory: false }))
    }
  }, [config])

  if (!open)
    return (
      <Button variant="contained" color="primary" onClick={onOpen}>
        Copy Machine
      </Button>
    )

  const handleSubmit = () => {
    if (!config.location) setError('Location is required')
    else {
      onSubmit(config)
      onClose()
    }
  }

  const configCheckboxes = [
    { label: 'Copy payment methods', key: 'copyPaymentMethods' },
    { label: 'Copy menu items', key: 'copyMenuItems' },
    { label: 'Copy channels', key: 'copyChannels' },
    {
      label: 'Copy inventory',
      key: 'copyInventory',
      overrwrite: { disabled: !config.copyChannels || !config.copyMenuItems },
    },
  ]

  return (
    <Dialog
      title="Copy Machine"
      open={open}
      onClose={onClose}
      handleSubmit={handleSubmit}
      primaryActionText="Copy"
    >
      <div className={classes.configContainer}>
        <TextField
          label="Location"
          variant="outlined"
          className={classes.nameInput}
          value={config.location}
          onChange={(e) =>
            setConfig((prevConfig) => ({
              ...prevConfig,
              location: e.target.value,
            }))
          }
          error={!!error}
          helperText={error}
        />
        {configCheckboxes.map(({ label, key, overrwrite }) => (
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                color="primary"
                onChange={(_e, checked) =>
                  setConfig((prevConfig) => ({
                    ...prevConfig,
                    [key]: checked,
                  }))
                }
                checked={config[key as keyof typeof config] as boolean}
                {...overrwrite}
              />
            }
            label={label}
          />
        ))}
      </div>
    </Dialog>
  )
}
