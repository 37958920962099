import React, { useState } from 'react'
import useEditProduct from './hooks'

import { Link, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { useMutation, useQuery } from '@apollo/client'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import Spinner from '../components/spinner'
import Title from '../components/Title'
import Dialog from '../components/dialog'
import { ProductEditor } from './ProductEditor'
import { getTextForLocale } from '../helpers'
import {
  ActivateProductDocument,
  DeactivateProductDocument,
  ProductByIdDocument,
  UpdateProductDocument,
} from '../generated'

const useStyles = makeStyles((theme) => ({
  backArrow: {
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' },
  },
  goodButton: {
    marginTop: '0.5rem',
    backgroundColor: '#2ecc71',
    color: '#fff',
  },
  badButton: { marginTop: '0.5rem', backgroundColor: '#e74c3c', color: '#fff' },
}))

const SingleProductScreen = () => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const params = useParams()
  const { loading, error, data, refetch } = useQuery(ProductByIdDocument, {
    variables: { id: params.id },
  })
  const [updateProduct] = useMutation(UpdateProductDocument)
  const [activateProduct] = useMutation(ActivateProductDocument)
  const [deactivateProduct] = useMutation(DeactivateProductDocument)

  const {
    product,
    handleValueChange,
    handleLabelChange,
    handleImageChange,
    handleTextChange,
    resetValues,
  } = useEditProduct(data?.productById)
  const [updating, setUpdating] = useState(false)

  const handleCancelClicked = () => {
    setEditMode(false)
    resetValues()
  }

  const handleEditModeClicked = () => {
    if (editMode) {
      const { id: productId, active, labels, ...fields } = product
      setUpdating(true)
      updateProduct({
        variables: {
          input: {
            ...fields,
            productId,
            labelIds: labels.map((l) => l.id),
            images: product.images.map(({ imageUrl, imageType }) => ({
              imageUrl,
              imageType,
            })),
            texts: product.texts.map(
              ({ name, description, allergens, locale }) => ({
                name,
                description,
                allergens,
                locale,
              })
            ),
            price: parseInt(product.price),
            shelfLife: product.shelfLife ? parseInt(product.shelfLife) : undefined,
            procurementPrice: product.procurementPrice ? parseInt(product.procurementPrice) : undefined,
            collaborationOrigin: product.collaborationItem ? product.collaborationOrigin : '',
            stockLevelAlert: 0,
          },
        },
      })
        .then(refetch)
        .then(() => setEditMode(false))
        .then(() => setUpdating(false))
    } else setEditMode(true)
  }

  const handleToggleActive = async () => {
    const args = {
      variables: { id: data.productById.id },
      refetchQueries: [
        { query: ProductByIdDocument, variables: { id: params.id } },
      ],
    }
    if (data.productById.active) await deactivateProduct(args)
    else await activateProduct(args)
    setDialogOpen(false)
    setEditMode(false)
  }

  if (!product || !data) return <Spinner />
  // console.log(data.productById)
  if (error) return <p>Error {JSON.stringify(error, null, 2)}</p>

  const isActive = data.productById.active
  const name = getTextForLocale(data.productById).name

  return (
    <>
      <Dialog
        title="Are you sure?"
        open={dialogOpen}
        content={`Are you sure you want to ${
          isActive ? 'deactivate' : 'activate'
        } ${getTextForLocale(data.productById).name}?`}
        onClose={() => setDialogOpen(false)}
        handleSubmit={handleToggleActive}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Link className={classes.backArrow} to="/products">
            &larr; Go back
          </Link>
          <Title>{name}</Title>
        </Grid>
      </Grid>
      <ProductEditor
        loading={loading}
        error={error}
        product={product}
        editMode={editMode}
        onValueChange={handleValueChange}
        onImageChange={handleImageChange}
        onLabelChange={handleLabelChange}
        onTextChange={handleTextChange}
        updating={updating}
      />
      <Button
        onClick={handleEditModeClicked}
        variant="contained"
        color="primary"
        disabled={!data || updating}
      >
        {editMode ? 'Save' : 'Edit'}
      </Button>
      {editMode && (
        <>
          <Button
            onClick={resetValues}
            variant="contained"
            color="secondary"
            style={{ marginLeft: '6px' }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={handleCancelClicked}
            style={{ marginLeft: '6px' }}
          >
            Cancel
          </Button>
          <br />
          <Button
            variant="contained"
            onClick={() => setDialogOpen(true)}
            className={isActive ? classes.badButton : classes.goodButton}
          >
            {isActive ? 'Dectivate' : 'Activate'}
          </Button>
        </>
      )}
    </>
  )
}

export default SingleProductScreen
