import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Tooltip from '@material-ui/core/Tooltip'

import DashboardIcon from '@material-ui/icons/Dashboard'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import LayersIcon from '@material-ui/icons/Layers'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import MoneyIcon from '@material-ui/icons/Money'
import FastfoodRoundedIcon from '@material-ui/icons/FastfoodRounded'
import ErrorIcon from '@material-ui/icons/Error'
import StorefrontIcon from '@material-ui/icons/Storefront'
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

import { useAuth } from './context/auth'
import path from 'path'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },
  // keep right padding when drawer closed
  toolbar: { paddingRight: 24 },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: { marginRight: 36 },
  menuButtonHidden: { display: 'none' },
  title: { flexGrow: 1 },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: { flexGrow: 1, height: '100vh', overflow: 'auto' },
  containerRoot: { flexGrow: 1 },
  container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4) },
  fixedHeight: { height: 240 },
  activeIcon: { color: green[900] },
}))

const theme = createMuiTheme({
  typography: { subtitle2: { fontSize: 12 } },
  palette: { primary: { main: green[900] }, secondary: { main: green[100] } },
})

const AppShell = ({ children }: { children: React.ReactNode }) => {
  // console.log('base', process.env.REACT_APP_BASE_URL);
  const location = useLocation()
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const { logOut } = useAuth()

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const isActiveRoute = (path: string) =>
    path === '/'
      ? path === location.pathname
      : location.pathname.startsWith(path)

  const menus = [
    [
      {
        header: 'Home',
        links: [{ path: '/', icon: DashboardIcon, name: 'Dashboard' }],
      },
      {
        header: 'Manage',
        links: [
          { path: '/inventory', icon: LayersIcon, name: 'Inventory' },
          { path: '/menus', icon: RestaurantMenuIcon, name: 'Menus' },
          { path: '/products', icon: FastfoodRoundedIcon, name: 'Products' },
          { path: '/discounts', icon: MoneyIcon, name: 'Discounts' },
          { path: '/machines', icon: StorefrontIcon, name: 'Machines' },
          { path: '/customers', icon: GroupIcon, name: 'Customers' },
          { path: '/settings', icon: SettingsIcon, name: 'Settings' },
        ],
        
      },
    ],
    [
      {
        header: 'Reports',
        links: [
          { path: '/orders', icon: ShoppingCartIcon, name: 'Orders' },
          { path: '/errors', icon: ErrorIcon, name: 'Errors' },
        ],
      },
    ],
  ]

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Dashboard
            </Typography>
            <IconButton onClick={logOut} color="inherit">
              <Badge badgeContent={0} color="secondary">
                <ExitToAppIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {menus.map((section, idx) => (
            <React.Fragment key={section.toString()}>
              {idx !== 0 && <Divider />}
              <List>
                <div>
                  {section.map(({ header, links }) => (
                    <React.Fragment key={header}>
                      <ListSubheader inset>{header}</ListSubheader>
                      {links.map(({ path, icon: Icon, name }) => (
                        <Link to={path} key={name}>
                          <ListItem button>
                            <Tooltip title={name}>
                              <ListItemIcon>
                                <Icon
                                  className={
                                    isActiveRoute(path)
                                      ? classes.activeIcon
                                      : ''
                                  }
                                />
                              </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={name} />
                          </ListItem>
                        </Link>
                      ))}
                    </React.Fragment>
                  ))}
                </div>
              </List>
            </React.Fragment>
          ))}
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.containerRoot}>
            <Container className={classes.container}>
              {children || ''}
            </Container>
          </div>
        </main>
      </ThemeProvider>
    </div>
  )
}

export default AppShell
