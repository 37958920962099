import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import Dialog from '../../components/dialog'

const useStyles = makeStyles({
  channelGrid: { marginTop: '24px', width: '500px' },
  channelRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '6px',
    width: '100%',
  },
  channelCell: {
    flex: 1,
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 600,
    backgroundColor: '#ddd',
    padding: '0.6rem',
    border: '1px solid #222',
    borderRadius: '6px',
    margin: '0 6px',
    '&:first-child': { marginLeft: 0 },
    '&:last-child': { marginRight: 0 },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f99',
      transition: '0.1s background-color',
    },
  },
})

export const ChannelConfiguration = ({
  onAddChannel,
  onRemoveChannel,
  channels,
}: {
  onAddChannel: (channel: number) => void
  onRemoveChannel: (channel: number) => void
  channels: number[]
}) => {
  const classes = useStyles()
  const [addChannelValue, setAddChannelValue] = useState('')
  const [removeChannel, setRemoveChannel] = useState<number | null>(null)

  const handleChannelKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 && addChannelValue !== '') {
      const channel = parseInt(addChannelValue)
      if (channels.includes(channel)) {
        alert('Channel already exists')
      } else {
        onAddChannel(channel)
      }
    }
  }

  const handleRemoveChannel = async () => {
    if (removeChannel !== null) {
      await onRemoveChannel(removeChannel)
      setRemoveChannel(null)
    }
  }

  const renderChannelRepresentation = (channels: number[]) => {
    const channelsByGroup = channels.reduce(
      (acc, curr) => ({
        ...acc,
        [Math.floor(curr / 10)]: [...(acc[Math.floor(curr / 10)] || []), curr],
      }),
      {} as { [key: string]: number[] }
    )
    return Object.keys(channelsByGroup)
      .sort((a, b) => parseInt(b) - parseInt(a))
      .map((groupKey) => (
        <div className={classes.channelRow} key={groupKey}>
          {channelsByGroup[groupKey]
            .sort((a, b) => a - b)
            .map((channel) => (
              <div
                key={channel}
                className={classes.channelCell}
                onClick={() => setRemoveChannel(channel)}
              >
                {channel}
              </div>
            ))}
        </div>
      ))
  }

  return (
    <>
      <h2>Channels</h2>
      <TextField
        value={addChannelValue}
        onChange={(e) => setAddChannelValue(e.target.value)}
        label="Add channel"
        variant="outlined"
        onKeyDown={handleChannelKeyDown}
        type="number"
      />
      <div className={classes.channelGrid}>
        {renderChannelRepresentation(channels)}
      </div>
      <Dialog
        title="Are you sure?"
        open={removeChannel !== null}
        description={`Are you sure you want to delete channel ${removeChannel} from the configuration?`}
        onClose={() => setRemoveChannel(null)}
        handleSubmit={handleRemoveChannel}
        primaryActionText="Delete"
      />
    </>
  )
}
