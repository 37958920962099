import React from 'react'
import { format } from 'date-fns'
import { Link, useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Title from '../components/Title'
import Spinner from '../components/spinner'
import { getTextForLocale, toSekString } from '../helpers'
import { SendReceipt } from './sendReceipt'
import { OrderByIdDocument, PaymentMethod } from '../generated'
import { isCardPayment, isSwishPayment } from '../util'
import { OrderRefunds } from './OrderRefunds'

const useStyles = makeStyles((theme) => ({
  backArrow: {
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' },
  },

  infoRow: { marginTop: '0.5rem', marginBottom: '0.5rem' },
  label: { fontWeight: 700 },
  link: { color: theme.palette.primary.main, textDecoration: 'underline' },
}))

export const SingleOrderScreen = () => {
  const classes = useStyles()
  const params = useParams()
  const { loading, error, data } = useQuery(OrderByIdDocument, {
    variables: { id: params.id || '' },
  })

  if (loading || !data) return <Spinner />
  if (error) return <p>Error {JSON.stringify(error, null, 2)}</p>

  const order = data.orderById

  const payment = order?.payment
  let paymentInfo

  if (payment) {
    paymentInfo = [
      { label: 'ID', value: payment.id },
      { label: 'Method', value: payment.method },
      { label: 'Status', value: payment.status },
    ]

    const merchantData = payment.merchantData

    if (isSwishPayment(merchantData)) {
      const error =
        merchantData.error?.errorCode &&
        ` ${merchantData.error.additionalInformation}`
      paymentInfo = [
        ...paymentInfo,
        { label: 'Swish ID', value: merchantData.id },
        { label: 'Phone number', value: merchantData.payerAlias },
        ...(error ? [{ label: 'Error', value: error }] : []),
      ]
    } else if (isCardPayment(merchantData)) {
      paymentInfo = [
        ...paymentInfo,
        { label: 'SessionId', value: merchantData.sessionID },
        {
          label: 'Retail Transaction ID',
          value: merchantData.retailTransactionID,
        },
        { label: 'Fingerprint', value: merchantData.fingerPrint },
      ]
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Link className={classes.backArrow} to="/orders">
            &larr; Go back
          </Link>
          <Title>Order: {order.id}</Title>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <p className={classes.infoRow}>
            <span className={classes.label}>ID</span>: {order.id}
          </p>
          <p className={classes.infoRow}>
            <span className={classes.label}>Date</span>:{' '}
            {format(new Date(order.orderDate), 'yyyy-MM-dd HH:mm')}
          </p>
          <p className={classes.infoRow}>
            <span className={classes.label}>Status</span>: {order.status}
          </p>
          <p className={classes.infoRow}>
            <span className={classes.label}>Price</span>:{' '}
            {toSekString(order.totalPrice)}
          </p>
          <p className={classes.infoRow}>
            <span className={classes.label}>Reduction</span>:{' '}
            {toSekString(order.totalReduction)}
          </p>
          <p className={classes.infoRow}>
            <span className={classes.label}>Discount</span>:{' '}
            {order.discount?.code}
          </p>
          <p className={classes.infoRow}>
            <span className={classes.label}>Machine</span>:{' '}
            <Link to={`/machines/${order.machine.id}`} className={classes.link}>
              {order.machine.location}
            </Link>
          </p>
          <p className={classes.infoRow}>
            <span className={classes.label}>Items</span>:{' '}
            {order.orderItems
              .map((o) => getTextForLocale(o.product).name)
              .join(', ')}
          </p>
          <br />
          {payment && paymentInfo && (
            <>
              <h2 style={{ marginTop: '0' }}>Payment</h2>
              {paymentInfo.map(({ label, value }) => (
                <p className={classes.infoRow}>
                  <span className={classes.label}>{label}</span>: {value}
                </p>
              ))}
              {payment.method === PaymentMethod.Card &&
                payment.cardCaptures.length > 0 && (
                  <p className={classes.infoRow}>
                    <span className={classes.label}>
                      This payment has been CAPTURED
                    </span>
                  </p>
                )}
              <br />
              <OrderRefunds
                payment={payment}
                discount={order.discount}
                orderItems={order.orderItems}
                orderGrossPrice={order.totalPrice}
                orderId={order.id}
              />
            </>
          )}
        </Grid>

        {order.status === 'PAID' && payment && (
          <Grid item xs={12}>
            <SendReceipt paymentId={payment.id} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
