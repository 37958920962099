import React, { useState, useEffect } from 'react'

import { gql, useQuery } from '@apollo/client'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import Accordion from '@material-ui/core/Accordion'
import Button from '@material-ui/core/Button'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Spinner from '../components/spinner'
import Title from '../components/Title'
import { Link } from 'react-router-dom'
import { getTextForLocale } from '../helpers'

const useStyles = makeStyles((theme) => ({
  card: { '&:hover': { cursor: 'pointer' } },

  paper: { padding: theme.spacing(2) },

  image: { padding: theme.spacing(1), height: 128, width: 'auto' },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const GET_PRODUCTS_QUERY = gql`
  query Products($activeOnly: Boolean) {
    products(activeOnly: $activeOnly) {
      id
      category
      price
      images {
        imageType
        imageUrl
      }
      texts {
        name
        description
        allergens
        locale
      }
      active
    }
  }
`

const Products = ({ loading, error, products }) => {
  const classes = useStyles()
  if (loading || !products) return <Spinner />
  if (error) return <p>Error :( {JSON.stringify(error, null, 2)}</p>

  return products
    .slice()
    .sort((a, b) => b.active - a.active)
    .map((product) => {
      const { name, description, allergens } = getTextForLocale(product)
      const splashImage =
        product.images.find((img) => img.imageType === 'SPLASH') ||
        product.images[0]
      return (
        <Grid item xs={12} sm={6} md={4} key={product.id}>
          <Grid item xs={12}>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item xs>
                <Accordion className={classes.card}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={12} className={classes.image}>
                      <img
                        style={{
                          margin: 'auto',
                          display: 'block',
                          maxWidth: '100%',
                          maxHeight: '100%',
                        }}
                        alt={name}
                        src={splashImage?.imageUrl}
                      ></img>
                    </Grid>

                    <Grid item xs={12} className={classes.image}>
                      <Typography className={classes.heading}>
                        {name}
                      </Typography>
                      <Typography>{product.price} kr</Typography>
                      {!product.active && (
                        <Typography
                          style={{ color: 'crimson', fontWeight: 'bold' }}
                        >
                          INACTIVE
                        </Typography>
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        {product.category}
                      </Typography>

                      <Typography variant="body2" gutterBottom>
                        {description}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Allergens: {allergens}
                      </Typography>

                      <Button
                        to={`/products/${product.id}`}
                        variant="contained"
                        color="primary"
                        component={Link}
                      >
                        Go to
                      </Button>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    })
}

const ProductsScreen = () => {
  const [activeOnly, setActiveOnly] = useState(true)
  const { loading, error, data, refetch } = useQuery(GET_PRODUCTS_QUERY, {
    variables: { activeOnly },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (data) {
      refetch({
        activeOnly,
      })
    }
  }, [activeOnly, data, refetch])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Products</Title>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={() =>
                  setActiveOnly((prevActiveOnly) => !prevActiveOnly)
                }
                value={activeOnly}
                checked={activeOnly}
              />
            }
            label="Active Only"
            labelPlacement="left"
            style={{ marginLeft: '6px' }}
          />

          <Button
            to={`/products/create`}
            variant="contained"
            color="primary"
            component={Link}
            style={{ marginTop: '12px' }}
          >
            + CREATE PRODUCT
          </Button>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Products loading={loading} error={error} products={data?.products} />
      </Grid>
    </>
  )
}

export default ProductsScreen
