import React, {
    useState,
    useRef,
    // useEffect
} from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'

import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'

import FormControl from '@material-ui/core/FormControl'

import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'

import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

import { useAuth } from '../context/auth'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },

  div: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },

  content: { flexGrow: 1, height: '100vh', overflow: 'auto' },
  form: { display: 'flex', flexDirection: 'column' },
  card: { padding: theme.spacing(8) },
  field: { marginBottom: 20, width: 250 },
}))

const theme = createMuiTheme({ palette: { primary: { main: green[900] } } })

const Login = () => {
  const classes = useStyles()

  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const emailRef = useRef<HTMLInputElement>()
  const passwordRef = useRef<HTMLInputElement>()
  const { logIn, error } = useAuth()

  const handleForm = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    await logIn(
      emailRef.current?.value as string,
      passwordRef.current?.value as string
    )
    setLoading(false)
  }

  const handleMouseDownPassword = (e: React.MouseEvent) => {
    e.preventDefault()
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <ThemeProvider theme={theme}>
        <main className={classes.content}>
          <div className={classes.div}>
            <Card className={classes.card}>
              <form onSubmit={handleForm}>
                <FormControl className={classes.form}>
                  <InputLabel htmlFor="email-field">Email</InputLabel>
                  <Input
                    id="email-field"
                    inputRef={emailRef}
                    className={classes.field}
                  />
                </FormControl>

                <FormControl className={classes.form}>
                  <InputLabel htmlFor="password-field">Password</InputLabel>
                  <Input
                    id="password-field"
                    inputRef={passwordRef}
                    type={showPassword ? 'text' : 'password'}
                    className={classes.field}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowPassword((prevState) => !prevState)
                          }
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Button variant="contained" color="primary" type="submit">
                    {loading ? (
                      <CircularProgress size={24} color="primary" />
                    ) : (
                      'Sign in'
                    )}
                  </Button>
                </FormControl>
              </form>
              <Typography
                style={{ marginTop: 20 }}
                variant="body2"
                color="textSecondary"
              >
                {error}
              </Typography>
            </Card>
          </div>
        </main>
      </ThemeProvider>
    </div>
  )
}

export default Login
