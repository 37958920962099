import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Title from '../components/Title'
import MachineLocationsScreen from './MachineLocationsScreen'
import LocationCategoriesScreen from './LocationCategoriesScreen'
import LocationTypesScreen from './LocationTypesScreen'
import GeographicalLocationsScreen from './GeographicalLocationsScreen'

const useStyles = makeStyles((theme) => ({
  toLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export const SettingsScreen = () => {
  const classes = useStyles()
  const { tab } = useParams() // Get the tab parameter from the URL
  const navigate = useNavigate()
  const [value, setValue] = useState(0)

  useEffect(() => {
    // Set the initial tab value based on the URL parameter
    if (tab) {
      const tabIndex = parseInt(tab, 10)
      if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex <= 3) {
        setValue(tabIndex)
      }
    }
  }, [tab])

  const handleChange = (event, newValue) => {
    setValue(newValue)
    navigate(`/settings/${newValue}`) // Update the URL parameter when the tab changes
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Title>Settings</Title>
        </Grid>
        <Grid item xs={12}>
          <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
            <Tab className="text-left" label="Locations" />
            {/* <Tab label="Location Categories" />
            <Tab label="Location Types" />
            <Tab label="Geographical Locations" /> */}
          </Tabs>
          <TabPanel value={value} index={0} className={classes.tabContent}>
            <MachineLocationsScreen />
          </TabPanel>
          {/* <TabPanel value={value} index={1} className={classes.tabContent}>
            <LocationCategoriesScreen />
          </TabPanel>
          <TabPanel value={value} index={2} className={classes.tabContent}>
            <LocationTypesScreen />
          </TabPanel>
          <TabPanel value={value} index={3} className={classes.tabContent}>
            <GeographicalLocationsScreen />
          </TabPanel> */}
        </Grid>
      </Grid>
    </>
  )
}

export default SettingsScreen

