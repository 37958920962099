import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Pagination from '@material-ui/lab/Pagination'

import Title from '../components/Title'
import Spinner from '../components/spinner'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import {
  ConsentedCustomersDocument,
  ConsentedCustomersCountDocument,
} from '../generated'
import { FormControlLabel, Switch } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  toLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
}))


const CustomersTable = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()
  const headers = [
    'Created',
    'Consent Marketing',
    'Marketing consent date',
    'Phone',
    'Consent phone',
    'Consent phone date',
    'Email',
    'Consent email',
    'Consent email date',
    'Loyalty',
    'Loyalty date',
  ]

  return (
    <Paper style={{ width: '100%' }}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((h) => (
                <TableCell key={h}>{h}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

const CustomersScreen = () => {
  const [open, setOpen] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [loyaltyOnly, setLoyaltyOnly] = useState(false)
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }
  
  const ConsentedCustomers = () => {
    const variables = {
      input: {
        LoyaltyOnly: loyaltyOnly,
        Limit: pageSize,
        Offset: (page - 1) * pageSize,
      },
    };
    const { loading, data, error, refetch } = useQuery(ConsentedCustomersDocument, { variables })
    const { data: countData, refetch: countRefetch } = useQuery(ConsentedCustomersCountDocument)
  
    useEffect(() => {
      if (data) refetch()
    }, [data, refetch])
    useEffect(() => {
      if (countData) {
        countRefetch()
        console.log('countData', countData)
        setTotal(countData.consentedCustomersCount || 0)
      }
    }, [countData, countRefetch])
  
    const formatDate = (date: string) => date.replace('T', ' ').slice(0, 16)
  
    if (loading || !data) return <><Spinner /></>
    if (error) return <p>Error : {JSON.stringify(error, null, 2)}</p>
  
    return (
      <>
        {data.consentedCustomers.map((customer) => (
          <TableRow key={customer.id}>
            <TableCell>{formatDate(customer.createdAt)}</TableCell>
            <TableCell>{customer.marketingConsent ? 'Yes' : 'No'}</TableCell>
            <TableCell>{customer.consentedToMarketingAt ? formatDate(customer.consentedToMarketingAt) : '' }</TableCell>
            <TableCell>{customer.phoneNumber}</TableCell>
            <TableCell>{customer.consentPhone ? 'Yes' : 'No' }</TableCell>
            <TableCell>{customer.consentPhoneAt ? formatDate(customer.consentPhoneAt) : '' }</TableCell>
            <TableCell>{customer.email}</TableCell>
            <TableCell>{customer.consentEmail ? 'Yes' : 'No' }</TableCell>
            <TableCell>{customer.consentEmailAt ? formatDate(customer.consentEmailAt) : '' }</TableCell>
            <TableCell>{customer.loyaltySignup ? 'Yes' : 'No' }</TableCell>
            <TableCell>{customer.loyaltySignupAt ? formatDate(customer.loyaltySignupAt) : '' }</TableCell>
          </TableRow>
        ))}
        {/* {editModalDiscount && (
          <EditDiscount
            open={!!editModalDiscount}
            onClose={() => setEditModalDiscount(null)}
            discount={editModalDiscount}
            activeOnly={activeOnly}
          />
        )} */}
      </>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Title>Customers</Title>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={() =>
                  setLoyaltyOnly((prevLoyaltyOnly) => !prevLoyaltyOnly)
                }
                value={loyaltyOnly}
                checked={loyaltyOnly}
              />
            }
            label="Loyalty customers only"
            labelPlacement="start"
          />
        </Grid>

          <CustomersTable>
          <ConsentedCustomers />
        </CustomersTable>
        <Grid item xs={12}>
          <Pagination siblingCount={6} count={Math.ceil(total/pageSize)} page={page} onChange={handleChange} />
        </Grid>
      </Grid>

      {/* {open && (
        <CreateDiscount open={open} setOpen={setOpen} activeOnly={activeOnly} />
      )} */}
    </>
  )
}

export default CustomersScreen
