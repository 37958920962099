import React, { useState } from 'react'

import { format } from 'date-fns'

import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { Link, useLocation } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

import Title from '../components/Title'
import Spinner from '../components/spinner'
import DateIntervalPicker from '../components/dateIntervalPicker'
import SelectMachine from '../components/selectMachine'

import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { getTextForLocale, toSekString } from '../helpers'
import {
  FilteredOrdersDocument,
  OrderStatus,
  PaymentMethod,
  Products_TextsDocument,
} from '../generated'
import { useSyncStateToSearchParams } from '../hooks'
import { Chip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' },
  },
}))

const OrderTable = ({ orders }) => {
  const classes = useStyles()

  const headers = [
    { text: 'Date' },
    { text: 'Order ID' },
    { text: 'Machine' },
    { text: 'Status' },
    { text: 'Order items' },
    { text: 'Order Amount', align: 'right' },
    { text: 'Discount Amount', align: 'right' },
    { text: 'Discount Code', align: 'right' },
  ]

  const orderStatusToBgColor = {
    [OrderStatus.Paid]: '#7bed9f',
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {headers.map((h) => (
            <TableCell key={h.text} align={h.align || 'left'}>
              <b>{h.text}</b>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map((order) => {
          const orderStatus = order.payment?.refunds.length
            ? 'REFUNDED'
            : order.status
          return (
            <TableRow key={order.id} className={classes.orderRow}>
              <TableCell>
                {format(new Date(order.orderDate), 'yyyy-MM-dd HH:mm')}
              </TableCell>
              <TableCell>
                <Link to={`/orders/${order.id}`} className={classes.link}>
                  {order.id}
                </Link>
              </TableCell>
              <TableCell>
                <Link
                  to={`/machines/${order.machine.id}`}
                  className={classes.link}
                >
                  {order.machine.location}
                </Link>
              </TableCell>
              <TableCell>
                {
                  <Chip
                    label={orderStatus}
                    style={{
                      backgroundColor: orderStatusToBgColor[orderStatus],
                    }}
                  />
                }
              </TableCell>
              <TableCell>
                {order.orderItems.map((orderItem) => (
                  <Link
                    to={`/products/${orderItem.product.id}`}
                    className={classes.link}
                    style={{ marginRight: '4px' }}
                  >
                    {getTextForLocale(orderItem.product).name}
                  </Link>
                ))}
              </TableCell>
              <TableCell align="right">
                {toSekString(order.totalPrice)}
              </TableCell>
              <TableCell align="right">
                {toSekString(order.totalReduction)}
              </TableCell>
              <TableCell align="right">
                {order.discount ? order.discount.code : null}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

const INITIAL_FILTERS = {
  startDate: new Date(),
  endDate: new Date(),
  machine: 'ALL_MACHINES',
  status: [],
  paymentMethod: [],
  productIds: [],
}

const Filters = ({ filters, setFilters }) => {
  const { loading, error, data } = useQuery(Products_TextsDocument, {
    variables: { activeOnly: true },
  })
  const candidateProducts = data?.products.map((p) => p.id) || []
  const productIdToName =
    data?.products.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr.texts.find((t) => t.locale === 'EN')?.name,
      }),
      {}
    ) || {}

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Grid container spacing={0}>
        <Grid xs={12} item>
          <DateIntervalPicker
            start={filters.startDate}
            end={filters.endDate}
            onStartChange={(value) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                startDate: value,
              }))
            }
            onEndChange={(value) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                endDate: value,
              }))
            }
          />
        </Grid>
        <br />
        <Grid
          xs={12}
          item
          style={{ display: 'flex', alignItems: 'flex-end', marginTop: '12px' }}
        >
          <div style={{ marginTop: '12px' }}>
            <SelectMachine
              allowAll={true}
              machineId={filters.machine}
              setMachineId={(value) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  machine: value,
                }))
              }
            />
          </div>
          <Autocomplete
            multiple
            options={Object.values(OrderStatus)}
            value={filters.status}
            size="small"
            onChange={(_, value) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                status: value,
              }))
            }
            style={{ width: '300px', marginLeft: '12px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Order Status"
                placeholder="Status"
              />
            )}
          />
          <Autocomplete
            multiple
            options={Object.values(PaymentMethod)}
            value={filters.paymentMethod}
            size="small"
            onChange={(_, value) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                paymentMethod: value,
              }))
            }
            style={{ width: '275px', marginLeft: '12px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Payment Method"
                placeholder="Method"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '24px' }}>
          <Autocomplete
            multiple
            options={candidateProducts}
            getOptionLabel={(option) => productIdToName[option]}
            value={filters.productIds}
            disabled={loading || !data || error}
            size="small"
            onChange={(_, value) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                productIds: value,
              }))
            }
            style={{ width: '275px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Products"
                placeholder="Product"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '24px' }}>
          <Button
            onClick={() => setFilters(INITIAL_FILTERS)}
            color="secondary"
            variant="contained"
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

const ParamKind = {
  Date: 'DATE',
  String: 'STRING',
  Array: 'ARRAY',
}

const getParamReader = (fallbacks, params) => (key, kind) => {
  const value = params.get(key)
  if (!value) return fallbacks[key]

  if (kind === ParamKind.String) return value
  if (kind === ParamKind.Date) return new Date(value)
  return value.split(',')
}

const OrdersScreen = ({ history }) => {
  const { search } = useLocation()
  const params = new URLSearchParams(search.substring(1))
  const readParam = getParamReader(INITIAL_FILTERS, params)
  const [filters, setFilters] = useState({
    startDate: readParam('startDate', ParamKind.Date),
    endDate: readParam('endDate', ParamKind.Date),
    machine: readParam('machine', ParamKind.String),
    status: readParam('status', ParamKind.Array),
    paymentMethod: readParam('paymentMethod', ParamKind.Array),
    productIds: readParam('productIds', ParamKind.Array),
  })
  useSyncStateToSearchParams(filters)

  const { loading, error, data } = useQuery(FilteredOrdersDocument, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filters: {
        date: {
          start: filters.startDate.toDateString(),
          end: filters.endDate.toDateString(),
        },
        ...(filters.machine !== 'ALL_MACHINES' && {
          machineId: filters.machine,
        }),
        ...(filters.status.length && { status: filters.status }),
        ...(filters.paymentMethod.length && {
          paymentMethod: filters.paymentMethod,
        }),
        ...(filters.productIds.length && { productIds: filters.productIds }),
      },
    },
  })

  const renderData = () => {
    if (loading) return <Spinner />
    if (error) return <p>Error {JSON.stringify(error, null, 2)}</p>
    if (data?.filteredOrders.length === 0) return <p>No orders matched query</p>
    return <OrderTable orders={data.filteredOrders} history={history} />
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Title>Orders</Title>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Filters filters={filters} setFilters={setFilters} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        {renderData()}
      </Grid>
    </>
  )
}

export default OrdersScreen
