import { initializeApp } from 'firebase/app'
import { browserLocalPersistence, initializeAuth } from 'firebase/auth'
import firebaseConfig from './firebase.config'

const app = initializeApp(firebaseConfig)
const auth = initializeAuth(app, { persistence: browserLocalPersistence })

export default Object.freeze({
  app,
  auth,
})
