import React from 'react'
import Typography from '@material-ui/core/Typography'
import { gql, useQuery } from '@apollo/client'
import { afterTax, toSekString } from '../../helpers'

const GET_MACHINE_BY_ID_QUERY = gql`
  query machineById($id: ID!) {
    machineById(id: $id) {
      location
    }
  }
`

const GET_GROSS_BY_MACHINE = gql`
  query todaysGross($machineId: ID) {
    todaysGross(machineId: $machineId)
  }
`

const GET_ORDER_COUNT_BY_MACINE = gql`
  query numberOfPaidOrders($sinceDate: Date, $machineId: ID) {
    numberOfPaidOrders(sinceDate: $sinceDate, machineId: $machineId)
  }
`

const sinceDate = new Date()
const SumTodaysOrders = ({ machineId }) => {
  const gross = useQuery(GET_GROSS_BY_MACHINE, { variables: { machineId } })
  const orderCount = useQuery(GET_ORDER_COUNT_BY_MACINE, {
    variables: {
      sinceDate,
      machineId,
    },
  })

  if (gross.loading || orderCount.loading) return <p>Loading</p>
  if (gross.error || orderCount.error) return <p>Error</p>

  const getOrderGross = () =>
    toSekString(Math.round(afterTax(gross.data.todaysGross)))

  return (
    <>
      <Typography component="p" variant="h5">
        {getOrderGross()}
      </Typography>
      <Typography component="p" variant="subtitle2">
        {orderCount.data.numberOfPaidOrders} orders
      </Typography>
    </>
  )
}

const TodaysOrderValueByMachine = ({ machineId }) => {
  const { loading, error, data } = useQuery(GET_MACHINE_BY_ID_QUERY, {
    variables: { id: machineId },
  })

  if (loading) return <p>Loading</p>
  if (error) return <p>Error</p>

  return (
    <React.Fragment>
      <Typography component="p">{data.machineById.location}</Typography>
      <div style={{ marginTop: 10 }}>
        <SumTodaysOrders machineId={machineId} />
      </div>
    </React.Fragment>
  )
}

export default TodaysOrderValueByMachine
