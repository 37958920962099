import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'

import Title from '../components/Title'
import Spinner from '../components/spinner'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import CreateDiscount from './CreateDiscount'
import EditDiscount from './EditDiscount'
import {
  DiscountsDocument,
  DiscountsQuery,
  MachinesDocument,
} from '../generated'

const useStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  toLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
}))

const Discounts = ({ activeOnly }: { activeOnly: boolean }) => {
  const { loading, data, error, refetch } = useQuery(DiscountsDocument, {
    variables: { activeOnly },
  })
  const { data: machinesData } = useQuery(MachinesDocument)
  const [editModalDiscount, setEditModalDiscount] = useState<
    DiscountsQuery['discounts'][0] | null
  >(null)

  const machineIdToLocation = machinesData?.machines.reduce(
    (acc, { id, location }) => ({
      ...acc,
      [id]: location,
    }),
    {} as { [key: string]: string }
  )

  useEffect(() => {
    if (data) refetch({ activeOnly })
  }, [activeOnly])

  const formatDate = (date: string) => date.replace('T', ' ').slice(0, 16)

  const renderMachinePills = (machineIds: string[]) => {
    if (!machineIds.length || !machineIdToLocation) return null
    return machineIds
      .slice()
      .sort((a, b) =>
        machineIdToLocation[a].localeCompare(machineIdToLocation[b])
      )
      .map((id) => (
        <Chip
          label={machineIdToLocation[id] as string}
          style={{ marginRight: '3px' }}
        />
      ))
  }

  if (loading || !data) return <Spinner />
  if (error) return <p>Error :( {JSON.stringify(error, null, 2)}</p>

  return (
    <>
      {data.discounts.map((discount) => (
        <TableRow key={discount.id}>
          <TableCell>{discount.code}</TableCell>
          <TableCell>{discount.reduction}</TableCell>
          <TableCell>{discount.type === 'PERCENTAGE' ? '%' : 'SEK'}</TableCell>

          <TableCell>{discount.singleUse ? 'TRUE' : 'FALSE'}</TableCell>
          <TableCell>{discount.active ? 'TRUE' : 'FALSE'}</TableCell>
          <TableCell>{formatDate(discount.validFrom)}</TableCell>
          <TableCell>{formatDate(discount.validTo)}</TableCell>
          <TableCell>{renderMachinePills(discount.validMachineIds)}</TableCell>
          <TableCell align="right">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setEditModalDiscount(discount)}
            >
              EDIT
            </Button>
          </TableCell>
        </TableRow>
      ))}
      {editModalDiscount && (
        <EditDiscount
          open={!!editModalDiscount}
          onClose={() => setEditModalDiscount(null)}
          discount={editModalDiscount}
          activeOnly={activeOnly}
        />
      )}
    </>
  )
}

const DiscountsTable = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()
  const headers = [
    'Code',
    'Reduction',
    'Type',
    'Single Use',
    'Active',
    'Valid From',
    'Valid To',
    'Valid Machines',
  ]
  return (
    <Paper style={{ width: '100%' }}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((h) => (
                <TableCell key={h}>{h}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

const DiscountsScreen = () => {
  const [open, setOpen] = useState(false)
  const [activeOnly, setActiveOnly] = useState(true)

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Title>Discounts</Title>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpen(true)}
            style={{ margin: '12px 0' }}
          >
            + ADD DISCOUNT
          </Button>

          <Link to="/discounts/batch">
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpen(true)}
              style={{ margin: '12px' }}
            >
              Batch Generate
            </Button>
          </Link>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={() =>
                  setActiveOnly((prevActiveOnly) => !prevActiveOnly)
                }
                value={activeOnly}
                checked={activeOnly}
              />
            }
            label="Active Only"
            labelPlacement="end"
            style={{ marginLeft: '6px' }}
          />
        </Grid>

        <DiscountsTable>
          <Discounts activeOnly={activeOnly} />
        </DiscountsTable>
      </Grid>

      {open && (
        <CreateDiscount open={open} setOpen={setOpen} activeOnly={activeOnly} />
      )}
    </>
  )
}

export default DiscountsScreen
