import React, { useCallback, useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

import { format } from 'date-fns'

import {
  Discount,
  DiscountType,
  OrderByIdDocument,
  OrderItem,
  Payment,
  PaymentStatus,
  ProductText,
  Refund,
  RefundAmountDocument,
} from '../generated'
import { Dialog } from '../components'
import {
  callIfValueIsNumericOrEmpty,
  getTextForLocale,
  toSekString,
} from '../helpers'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useMutation } from '@apollo/client'

const useStyles = makeStyles({
  table: {
    maxWidth: '500px',
    borderCollapse: 'collapse',
    borderRadius: '12px',
  },
  tableHeader: { fontWeight: 700 },

  helperText: {
    marginBottom: '0.25rem',
    paddingLeft: '0.25rem',
  },
  sliderContainer: { padding: '1rem', paddingBottom: 0, minWidth: '400px' },
  orderItemContainer: { display: 'flex', alignItems: 'center' },
})

const CreateRefund = ({
  open,
  onClose,
  orderGrossPrice,
  payment,
  discount,
  orderItems,
  onSubmit,
  refundLoading,
}: {
  open: boolean
  onClose: () => void
  orderGrossPrice: number
  payment: PartialPayment
  discount?: PartialDiscount
  orderItems: PartialOrderItem[]
  onSubmit: (refundAmount: number) => void
  refundLoading: boolean
}) => {
  const classes = useStyles()
  const [refundAmount, setRefundAmount] = useState<number | null>(0)
  const [selectedOrderItemIds, setSelectedOrderItemIds] = useState<Set<string>>(
    new Set()
  )

  const amountLeftToRefund =
    payment.totalAmount - payment.refunds.reduce((acc, r) => acc + r.amount, 0)

  const refundAmountExceedsAvailableAmount =
    refundAmount !== null && refundAmount > amountLeftToRefund

  
  const getPriceOfOrderItemAfterDiscount = useCallback((orderItem: PartialOrderItem) => {
    if (!discount) return orderItem.price
    if (discount.type === DiscountType.Percentage) {
      return Math.round(orderItem.price * (1 - discount.reduction / 100))
    }
    return Math.round(
      orderItem.price * (1 - discount.reduction / orderGrossPrice)
    )
  }, [discount, orderGrossPrice]);

  useEffect(() => {
    setRefundAmount(
      Array.from(selectedOrderItemIds).reduce(
        (acc, id) =>
          selectedOrderItemIds.has(id)
            ? acc +
              getPriceOfOrderItemAfterDiscount(
                orderItems.find((oi) => oi.id === id) as PartialOrderItem
              )
            : acc,
        0
      )
    )
  }, [selectedOrderItemIds, getPriceOfOrderItemAfterDiscount, orderItems])

  const renderRefundAmountHelper = () => {
    if (refundAmountExceedsAvailableAmount)
      return `Amount is too large! Maximum refund is ${toSekString(
        amountLeftToRefund
      )}`
    return `Amount left available to refund: ${toSekString(amountLeftToRefund)}`
  }

  const setRefundToPercentOfTotal = (percent: number) => {
    if (selectedOrderItemIds.size > 0) setSelectedOrderItemIds(new Set())
    setRefundAmount(
      Math.round(payment.totalAmount * (percent / 100) * 100) / 100
    )
  }

  const handleOrderItemCheck = (orderItemId: string, checked: boolean) => {
    if (checked) {
      setSelectedOrderItemIds((ids) => new Set(ids).add(orderItemId))
    } else {
      setSelectedOrderItemIds((ids) => {
        const newIds = new Set(ids)
        newIds.delete(orderItemId)
        return newIds
      })
    }
  }

  return (
    <Dialog
      title="Create Refund"
      open={open}
      onClose={onClose}
      handleSubmit={() => onSubmit(refundAmount ?? 0)}
      primaryActionText="Refund"
      primaryActionDisabled={
        refundLoading || refundAmountExceedsAvailableAmount
      }
    >
      <Typography
        className={classes.helperText}
        style={{ ...(refundAmountExceedsAvailableAmount && { color: 'red' }) }}
      >
        {renderRefundAmountHelper()}
      </Typography>
      <TextField
        variant="outlined"
        value={refundAmount}
        onChange={(e) =>
          callIfValueIsNumericOrEmpty(e.target.value, setRefundAmount)
        }
        fullWidth
        disabled={refundLoading}
        error={refundAmountExceedsAvailableAmount}
      />
      <Grid className={classes.sliderContainer}>
        <Slider
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}%`}
          step={5}
          marks
          min={0}
          max={100}
          value={
            refundAmount === null
              ? 0
              : Math.round((refundAmount / payment.totalAmount) * 100)
          }
          onChange={(e, value) => setRefundToPercentOfTotal(value as number)}
          disabled={refundLoading}
        />
      </Grid>
      <Grid>
        {orderItems.map((orderItem) => (
          <Grid className={classes.orderItemContainer} key={orderItem.id}>
            <FormControlLabel
              disabled={refundLoading}
              control={
                <Checkbox
                  checked={selectedOrderItemIds.has(orderItem.id)}
                  onChange={(e) =>
                    handleOrderItemCheck(orderItem.id, e.target.checked)
                  }
                />
              }
              label={`${getTextForLocale(orderItem.product).name},
                ${toSekString(getPriceOfOrderItemAfterDiscount(orderItem))}`}
            />
            <Typography></Typography>
          </Grid>
        ))}
      </Grid>
    </Dialog>
  )
}

type PartialPayment = Pick<Payment, 'id' | 'totalAmount' | 'status'> & {
  refunds: Partial<Refund> & Pick<Refund, 'id' | 'amount' | 'createdAt'>[]
}

type PartialDiscount = Pick<Discount, 'type' | 'reduction'> | null | undefined

type PartialOrderItem = Pick<OrderItem, 'id' | 'price'> & {
  product: {
    texts: Array<Partial<ProductText> & Pick<ProductText, 'name' | 'locale'>>
  }
}

export const OrderRefunds = <
  P extends PartialPayment,
  D extends PartialDiscount,
  OI extends PartialOrderItem
>({
  payment,
  discount,
  orderItems,
  orderGrossPrice,
  orderId,
}: {
  payment: P
  discount: D
  orderItems: OI[]
  orderGrossPrice: number
  orderId: string
}) => {
  const classes = useStyles()
  const [refundModalOpen, setRefundModalOpen] = useState(false)
  const [refundPayment, { loading }] = useMutation(RefundAmountDocument, {
    refetchQueries: [{ query: OrderByIdDocument, variables: { id: orderId } }],
  })

  if (payment.status !== PaymentStatus.Paid) return null

  const handleRefundSubmit = async (amount: number) => {
    await refundPayment({
      variables: { input: { paymentId: payment.id, amount } },
    })
    setRefundModalOpen(false)
  }

  const orderFullyRefunded =
    payment.totalAmount ===
    payment.refunds.reduce((acc, r) => acc + r.amount, 0)

  const openCreateRefundModalButton = (
    <Button
      color="primary"
      variant="contained"
      onClick={() => setRefundModalOpen(true)}
      style={{ margin: '12px 0' }}
      disabled={orderFullyRefunded}
    >
      + Create Refund
    </Button>
  )

  return (
    <Grid>
      <CreateRefund
        open={refundModalOpen}
        onClose={() => setRefundModalOpen(false)}
        orderGrossPrice={orderGrossPrice}
        payment={payment}
        onSubmit={handleRefundSubmit}
        discount={discount}
        orderItems={orderItems}
        refundLoading={loading}
      />

      <h2>Refunds</h2>
      {orderFullyRefunded ? (
        <Tooltip title="Order is already fully refunded">
          <span>{openCreateRefundModalButton}</span>
        </Tooltip>
      ) : (
        openCreateRefundModalButton
      )}

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Refund ID</TableCell>
            <TableCell className={classes.tableHeader}>Amount</TableCell>
            <TableCell className={classes.tableHeader}>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payment.refunds.map((r) => (
            <TableRow key={r.id}>
              <TableCell>{r.id}</TableCell>
              <TableCell>{toSekString(r.amount)}</TableCell>
              <TableCell>
                {(r.createdAt && format(new Date(r.createdAt), 'dd/MM yyyy')) ??
                  '?'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  )
}
