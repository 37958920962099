import React, { useState, useEffect, useCallback } from 'react'

import { useMutation } from '@apollo/client'

import Button from '@material-ui/core/Button'
import Dialog from '../components/dialog'
import { DiscountEditor } from './DiscountEditor'
import {
  UpdateDiscountInput,
  CreateDiscountInput,
  DiscountType,
  UpdateDiscountMutation,
  UpdateDiscountDocument,
  DiscountsDocument,
  DiscountFields,
} from '../generated'
import { fieldMask } from '../helpers'

const emptyDiscount = {
  code: '',
  reduction: 0,
  type: DiscountType.Percentage,
  active: false,
  singleUse: false,
  validFrom: new Date(),
  validTo: new Date(),
  validMachineIds: [],
}

const EditDiscount = ({
  open,
  onClose,
  discount,
  activeOnly,
}: {
  open: boolean
  onClose: () => void
  discount: UpdateDiscountInput
  activeOnly?: boolean
}) => {
  const [workingDiscount, setWorkingDiscount] =
    useState<CreateDiscountInput>(emptyDiscount)
  const [updateDiscount] = useMutation<UpdateDiscountMutation>(
    UpdateDiscountDocument
  )

  const resetDiscount = useCallback(() => {
    setWorkingDiscount(
      fieldMask(discount, [
        'code',
        'reduction',
        'type',
        'active',
        'singleUse',
        'validFrom',
        'validTo',
        'validMachineIds',
      ])
    )
  }, [discount])

  useEffect(() => {
    if (discount) {
      resetDiscount()
    }
  }, [discount, resetDiscount])

  const handleSubmit = async () => {
    if (!discount) alert('Discounts must have a reduction')
    await updateDiscount({
      variables: { input: { id: discount.id, ...workingDiscount } },
      refetchQueries: [{ query: DiscountsDocument, variables: { activeOnly } }],
    })
    onClose()
  }



  const handleChange = <K extends keyof DiscountFields>(
    field: K,
    value: DiscountFields[K]
  ) =>
    setWorkingDiscount((prevWorkingDiscount) => ({
      ...prevWorkingDiscount,
      [field]: value,
    }))

  return (
    <Dialog
      title="Edit Discount"
      open={open}
      onClose={onClose}
      description="Discounts codes are case sensitive. Single use means that the discount
        only can be used one time. If no code is supplied, a random 5 character code will be generated."
      actions={
        <>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={resetDiscount} variant="contained" color="secondary">
            Reset
          </Button>
          <Button onClick={onClose} variant="contained">
            Cancel
          </Button>
        </>
      }
    >
      <DiscountEditor
        handleChange={handleChange}
        discountConfig={workingDiscount}
      />
    </Dialog>
  )
}

export default EditDiscount
