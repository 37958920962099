import React, { useState } from 'react'

import { format } from 'date-fns'

import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'

import Title from '../components/Title'
import SelectMachine from '../components/selectMachine'
import Spinner from '../components/spinner'
import { ErrorsDocument, ErrorsQuery } from '../generated'

const useStyles = makeStyles((theme) => ({
  tableHead: { fontWeight: 'bold' },

  toLeft: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingRight: '0',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

export const statusMessagesByCode = {
  '0x30': 'OK',
  '0x31': 'IN PROCESS - BUSY',
  '0x32': 'INVALID TRAY REQUESTED',
  '0x33': 'INVALID CHANNEL REQUESTED',
  '0x34': 'EMPTY CHANNEL',
  '0x35': 'FAULT IN ELEVATOR MOTOR (POSSIBLE JAM)',
  '0x36': 'FAULT IN ELEVATOR BELT OR PHOTO SENSORS',
  '0x37': 'FAULT IN ANY OF THE CABINET’S PHOTOTRANSISTORS',
  '0x38': 'NO CHANNELS DETECTED',
  '0x39': 'FAULT IN PRODUCT DETECTOR',
  '0x41': 'OK', // Code acctually means "FAULT IN 485 BUS", but seems to be ok
  '0x42': 'PRODUCT UNDER THE ELEVATOR ALARM',
  '0x43': 'ERROR WHEN ELEVATOR APROACHING TO A POSITION',
  '0x44': 'FAULT IN KEYBOARD',
  '0x45': 'EEPROM WRITING ERROR',
  '0x46': 'FAULT COMMUNICATING WITH TEMPERATURE CONTROL',
  '0x47': 'THERMOMETER DISCONNECTED',
  '0x48': 'THERMOMETER PROGRAMMING LOST',
  '0x49': 'THERMOMETER FAULTY',
  '0x4A': 'CHANNELS POWER COMSUMPTION DETECTOR FAULTY',
  '0x4B': 'ELEVATOR DOES NOT FIND CHANNEL/TRAY',
  '0x4C': 'ELEVATOR DOES NOT FIND DELIVERY PRODUCT POSITION',
  '0x50': 'PRODUCT EXPIRED BECAUSE TEMPERATURE',
}

const ErrorsScreen = () => {
  const classes = useStyles()
  const { loading, error, data } = useQuery<ErrorsQuery>(ErrorsDocument)
  const [machineId, setMachineId] = useState('ALL_MACHINES')

  if (loading || !data) return <Spinner />
  if (error) return <p>Error {JSON.stringify(error, null, 2)}</p>

  const getFilteredErrors = () =>
    machineId !== 'ALL_MACHINES'
      ? data.errors.filter((error) => error.machine?.id === machineId)
      : data.errors

  const tableHeaders = [
    'Date',
    'Time',
    'Machine Location',
    'Error type',
    'Requested Channel',
  ]

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Title>Logged errors</Title>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.toLeft}>
            <SelectMachine
              allowAll={true}
              machineId={machineId}
              setMachineId={setMachineId}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((h) => (
                <TableCell className={classes.tableHead}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getFilteredErrors().map((error) => (
              <TableRow>
                <TableCell>
                  {format(new Date(error.date), 'yyyy-MM-dd')}
                </TableCell>
                <TableCell>{format(new Date(error.date), 'H:mm')}</TableCell>
                <TableCell>{error.machine?.location}</TableCell>
                <TableCell>
                  {error.statusCode}
                  {statusMessagesByCode.hasOwnProperty(error.statusCode) &&
                    `: ${
                      statusMessagesByCode[
                        error.statusCode as keyof typeof statusMessagesByCode
                      ]
                    }`}
                </TableCell>
                <TableCell>{error.metadata?.requestedChannel}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </>
  )
}

export default ErrorsScreen
